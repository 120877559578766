// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state) => {
      state.isLoggedIn = true;
    },
    setLogout: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setLogin, setLogout } = authSlice.actions;
export const isLoggedIn = (state) => state.auth.isLoggedIn;

export default authSlice.reducer;
