import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import ScrollToTop from "../../utils/ScrollToTop";
// Higher-Order-Component
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";

// Styles
import "react-loading-skeleton/dist/skeleton.css";
import "../../assets/scss/base/pages/faq/faq.scss";

// images
import FAQBannerImage from "../../assets/images/FAQ/Banner.png";
import { Helmet } from "react-helmet";
const FAQMobile = () => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <ScrollToTop />
      <div className="faq-banner-block">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Faq</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
        <div className="faq-banner-text">
          <h2>FAQs</h2>
          <p>
            Have questions? Here you’ll find the answers most valued by our
            partners, along with access to step-by-step instructions and
            support.
          </p>
        </div>
        <div className="faq-banner-img-block">
          <img src={FAQBannerImage} alt="" />
        </div>
      </div>
      <div className="faq-content-block">
        <Accordion flush open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              1. How can I login if I signed up using social platform like
              Facebook, Google etc?
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <p>
                If you signed up using a social platform such as Facebook or
                Google, please note that you can reset your password at any time
                by following these steps:
              </p>
              <br />
              <p>
                Visit our website or mobile app and click on the "Forgot
                Password" link.
              </p>
              <p>
                Select the option to reset your password using your registered
                email address.
              </p>
              <p>Check your email inbox or spam for the OTP.</p>
              <p>Reset your password and Login with the new password.</p>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">
              2. Why does it ask me to reset my password immediately when I try
              to login on new platform?
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <p>
                As part of our ongoing efforts to enhance your user experience,
                we have successfully migrated our systems to a new platform. To
                ensure the security of your account, we kindly ask you to reset
                your password after the login.
              </p>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">
              3. Why can’t I see my previous orders in the order history?
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <p>
                During our recent system migration, there were updates and
                improvements made to enhance our platform. As a result, you
                might not see your previous orders in the order history.{" "}
              </p>
              <p>
                if you have specific details about a previous order or if
                there's anything specific you're looking for, our customer
                support team is here to assist you. You can reach us at
                marketing@pineapple.my, and we'll do our best to provide you
                with the information you need promptly
              </p>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default FAQMobile;
