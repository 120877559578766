import "../../assets/scss/base/pages/cart/BillContainer.scss";
import {pcoin, mask, downarrow, coupon_sign} from '../../assets/images/cart/index';
import { useSelector } from "react-redux";
import { selectUserPCoinBalance } from "../../redux/utilitySlice";

const BillContainer = () => {
  const pCoinBalance = useSelector(selectUserPCoinBalance);
  return (
    <div className="frame-parent3BillContainer">
      <div className="frame-wrapperBillContainer">
        <div className="frame-wrapper1BillContainer">
          <div className="mask-group-parentBillContainer">
            {/* <img className="mask-group-iconBillContainer" alt="" src={mask} /> */}
            <div className="p-coin-parentBillContainer">
              <img className="p-coin-iconBillContainer" alt="pcoin" src={pcoin} />
              <div className="parentBillContainer">
                <div className="divBillContainer">{pCoinBalance ? pCoinBalance.toFixed(2) : 0.00}</div>
                <div className="available-p-coinsBillContainer">Available p-coins</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="frame-wrapper2BillContainer">
        <div className="frame-parent4BillContainer">
          <div className="frame-parent5BillContainer">
            <img className="frame-iconBillContainer" alt="sign" src={coupon_sign} />
            <div className="apply-couponBillContainer">Apply Coupon</div>
          </div>
          <img className="chevron-down-icon6BillContainer" alt="down-arrow" src={downarrow} />
        </div>
      </div> */}
      <div className="bill-details-parentBillContainer">
        <div className="bill-detailsBillContainer">Bill Details</div>
        <div className="frame-parent6BillContainer">
          <div className="sku-hrw-not-0001601-parentBillContainer">
            <div className="sku-hrw-not-0001601BillContainer">Sub Total</div>
            <div className="sku-hrw-not-00016011BillContainer">-</div>
          </div>
          <div className="sku-hrw-not-0001601-parentBillContainer">
            <div className="sku-hrw-not-0001601BillContainer">Discount on product</div>
            <div className="sku-hrw-not-00016013BillContainer">-</div>
          </div>
          <div className="sku-hrw-not-0001601-parentBillContainer">
            <div className="sku-hrw-not-0001601BillContainer">Shipping</div>
            <div className="sku-hrw-not-00016011BillContainer">-</div>
          </div>
          <div className="sku-hrw-not-0001601-parentBillContainer">
            <div className="sku-hrw-not-0001601BillContainer">Coupon Discount</div>
            <div className="sku-hrw-not-00016017BillContainer">-</div>
          </div>
          <div className="sku-hrw-not-0001601-parentBillContainer">
            <div className="sku-hrw-not-0001601BillContainer">P-Coin Redemption</div>
            <div className="sku-hrw-not-00016011BillContainer">-</div>
          </div>
          <div className="line-divBillContainer" />
          <div className="sku-hrw-not-0001601-parent3BillContainer">
            <div className="apply-couponBillContainer">Total</div>
            <div className="sku-hrw-not-000160111BillContainer">-</div>
          </div>
        </div>
      </div>
      <button className="button-wrapperBillContainer">
        <div className="buttonBillContainer">
          <div className="state-layer1BillContainer">
            <div className="divBillContainer">Place Order</div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default BillContainer;
