// ** Reducers Imports
import auth from "./authSlice";
import product from "./productSlice";
import utility from "./utilitySlice";
import categories from "./categoriesSlice";
import cart from "./cartSlice";
import address from "./addressSlice";
import wishlist from "./wishlistSlice";
import order from "./orderSlice";
import user from "./userSlice";
import modals from "./modalsSlice";
import notification from "./notificationSlice";

const rootReducer = {
  auth,
  product,
  utility,
  categories,
  cart,
  address,
  wishlist,
  order,
  user,
  modals,
  notification,
};

export default rootReducer;
