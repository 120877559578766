import axios from "axios";

import { getToken } from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

export const getAllWalletTransactions = async ({ filter = " " }) => {
  try {
    const token = getToken();
    const { tokenAccess } = token;
    const result = await axios.get(
      `${url}/pCoinsWallet/transections?filter=${filter}`,
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};
