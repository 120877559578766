import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/base/pages/subcategory/subcategoryDropdown.scss";
import { setCategoryDropdownValue } from "../../../redux/utilitySlice";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const SubCategoryDropdown = ({ category, SubCategory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const arr = [...SubCategory];
  const SortedArray = arr.sort((a, b) => {
    if (a.order === null) {
      return 1;
    }
    if (b.order === null) {
      return -1;
    }
    return a.order - b.order;
  });
  return (
    <div className="SubCategoryDropdown__Container">
      {SortedArray?.length > 0 &&
        SortedArray?.map((item, index) => {
          return (
            <div key={index} className="SubCategoryDropdown__Container__Inner">
              <p
                className="SubCategoryDropdown__Container__Inner__Head"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/catalogue/${category?.slug}/${item?.slug}`, {
                    state: {
                      id: item?.parentId,
                      subCatId: item?.id,
                      categoryName: category?.name,
                      subCatagoryName: item?.name,
                      SubCategory: category?.SubCategory,
                    },
                  });
                  dispatch(
                    setCategoryDropdownValue({
                      state: {
                        id: item?.parentId,
                        subCatId: item?.categoryId,
                        categoryName: category?.name,
                        subCatagoryName: item?.name,
                        SubCategory: category?.SubCategory,
                      },
                    })
                  );
                }}
              >
                <img
                  src={`${url}/${item?.icon}`}
                  alt=""
                  className="SubCategoryDropdown__Container__Inner__Icon"
                />{" "}
                {item?.name}
              </p>
              <div>
                {item?.SubCategoryType?.map((items,index) => {
                  return (
                    <p
                    key={index}
                      className="SubCategoryDropdown__Container__Inner__Text"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/catalogue/${category?.slug}/${item?.slug}/${items?.slug}`
                        );
                      }}
                    >
                      {" "}
                      {items?.name}
                    </p>
                  );
                })}
              </div>
              {item?.CategoryBrands?.map((currentItem, index) => {
                return (
                  <div
                  key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        setCategoryDropdownValue({
                          state: {
                            id: item?.parentId,
                            subCatId: currentItem?.categoryId,
                            brandId: currentItem?.Brand?.id,
                            categoryName: category?.name,
                            subCatagoryName: item?.name,
                            brandName: currentItem?.Brand?.name,
                            SubCategory: category?.SubCategory,
                          },
                        })
                      );
                      navigate(
                        `/catalogue/${category?.slug}/${item?.slug}/${currentItem?.Brand?.slug}`,
                        {
                          state: {
                            id: item?.parentId,
                            subCatId: currentItem?.categoryId,
                            brandId: currentItem?.Brand?.id,
                            categoryName: category?.name,
                            subCatagoryName: item?.name,
                            brandName: currentItem?.Brand?.name,
                            SubCategory: category?.SubCategory,
                          },
                        }
                      );
                    }}
                  >
                    <p className="SubCategoryDropdown__Container__Inner__Text">
                      {currentItem?.Brand?.name}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default SubCategoryDropdown;
