import { memo } from "react";
import "../../assets/scss/base/pages/cart/OrderingStepAddress.scss";

const OrderingStepAddress = memo(() => {
  return (
    <div className="address-child">
      <div className="frame-parent1">
        <div className="frame-parent2">
          <div className="icon-wrapper">
            <img className="icon2" alt="" src="/icon1.svg" />
          </div>
          <div className="bag">Bag</div>
        </div>
        <div className="frame-parent3">
          <div className="vector-parent">
            <img className="frame-item" alt="" src="/line-31.svg" />
            <div className="ellipse-wrapper">
              <div className="frame-inner" />
            </div>
          </div>
          <div className="bag">Address</div>
        </div>
        <div className="frame-parent4">
          <div className="vector-parent">
            <img className="frame-item" alt="" src="/line-32.svg" />
            <div className="frame-inner" />
          </div>
          <div className="bag">Payment</div>
        </div>
      </div>
    </div>
  );
});

export default OrderingStepAddress;
