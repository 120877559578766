import React from "react";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import AccountOverviewCard from "../../components/overview/AccountOverviewCard";
import { useNavigate } from "react-router-dom";

//images
import OrderIcon from "../../assets/images/account/Overview/OrderIcon.png";
import ProfileIcon from "../../assets/images/account/Overview/ProfileIcon.svg";
import PasswordIcon from "../../assets/images/account/Overview/PasswordIcon.svg";
import AddressIcon from "../../assets/images/account/Overview/AddressIcon.png";
import WhatsappIcon from "../../assets/images/account/Overview/WhatsappIcon.svg";
import WishlistIcon from "../../assets/images/account/Overview/WishlistIcon.png";

//style
import "../../assets/scss/base/pages/account/Overview/OverviewPage/OverviewPage.scss";
import { Helmet } from "react-helmet";

const OverviewPage = () => {
  const navigate = useNavigate();
  const BottomCardData = [
    {
      name: "Orders",
      icon: OrderIcon,
      link: "/account/orders",
    },
    {
      name: "Profile",
      icon: ProfileIcon,
      link: "/account/profile",
    },
    {
      name: "My wishlist",
      icon: WishlistIcon,
      link: "/wishlist-page",
    },
    {
      name: "Address Book",
      icon: AddressIcon,
      link: "/account/addressBook",
    },
    {
      name: "Change Password",
      icon: PasswordIcon,
      link: "/account/changepassword",
    },
    {
      name: "Whatsapp",
      icon: WhatsappIcon,
    },
  ];
  return (
    <AccountMenu>
      <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Overview</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div style={{ width: "100%" }}>
        <AccountOverviewCard />
        <div className="Bottom__Cards__Container">
          {BottomCardData.map((item, index) => {
            return (
              <div
                className={
                  item.name === "Whatsapp"
                    ? "BottomCard__GreenColor"
                    : "Bottom__Card"
                }
                key={index}
                onClick={() =>
                  item.name !== "Whatsapp"
                    ? navigate(item.link)
                    : window.open(
                        "https://api.whatsapp.com/send?phone=60193278400",
                        "_blank"
                      )
                }
              >
                <img src={item.icon} alt="icon" />
                <h6>{item.name}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </AccountMenu>
  );
};

export default OverviewPage;
