import axios from 'axios';

import { getToken } from '../utils/AuthService';

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

/**
* @function addAddressService
* @param 
* @description This function is used to add address item
*/


export const addAddressService = async ({
    receiptient, 
    address, 
    country,
    state,
    tin,
    city,
    zipCode,
    mobile,
    company,
    type,
    isDefault = false
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.post(`${url}/addresss/create`, {
            receiptient, 
            address, 
            country,
            state,
            city,
            zipCode,
            tin,
            mobile,
            isDefault,
            company,
            type
        },
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

/**
* @function updateAddressService
* @param 
* @description This function is used to update address item
*/


export const updateAddressService = async ({
    receiptient, 
    address, 
    country,
    state,
    tin,
    city,
    zipCode,
    mobile,
    company,
    id,
    type,
    isDefault = false
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.patch(`${url}/addresss/update?id=${id}`, {
            receiptient, 
            address, 
            country,
            state,
            city,
            tin,
            zipCode,
            mobile,
            isDefault,
            company,
            type
        },
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

/**
* @function addressListService
* @param 
* @description This function is used to get all Address item
*/


export const addressListService = async ({
    getAll = '',
    filter = '',
    limit = '',
    page = '',
    type = ''
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.get(`${url}/addresss/list?${getAll && '&getAll=' + getAll}${filter && '&filter=' + filter}${type && '&type=' + type}${limit && '&limit=' + limit}${page && '&page=' + page}`,
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

/**
* @function addressSingleService
* @param 
* @description This function is used to get single Address item
*/


export const addressSingleService = async ({
   id
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.get(`${url}/addresss/get?id=${id}`,
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

/**
* @function addressItemRemoveService
* @param 
* @description This function is used to remove Address item
*/

export const addressItemRemoveService = async ({
    id
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.delete(`${url}/addresss/remove?id=${id}`,
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

/**
* @function addressMakeDefaultService
* @param 
* @description This function is used to make default address item
*/

export const addressMakeDefaultService = async ({
    id,
    type
}) => {
    try {
        const tokens = getToken();
        const {tokenAccess, foodAccessToken} = tokens;
        const result = await axios.patch(`${url}/addresss/default?id=${id}`,{
            id,
            type
        },
        {
            headers: {
                Authorization: `Bearer ${tokenAccess}`,
                foodauthtoken: `${foodAccessToken}`
            }
        });
        return result;
    } catch (err) {
        return err;
    }
}

export default addressListService