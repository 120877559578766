import React from 'react';
import { useDispatch } from "react-redux";

// API-Service
import { addressItemRemoveService, addressListService } from '../../../service/address.service';

// Redux-Settings
import { 
  setShippingAddressList,
  setBillingAddressList,
  setDefaultShippingAddressId,
  setDefaultBillingAddressId
 } from '../../../redux/addressSlice';

function RemoveAddressModal({toggleRemoveModal, addressType, formValue}) {
    const dispatch = useDispatch();
    const fetchShippingAddressList = async () => {
      const response = await addressListService({
        getAll: true, 
        filter: 'Active', 
        type: "Shipping"
      });
      if(response?.data?.success){
        dispatch(setShippingAddressList(response?.data?.data?.list))
        dispatch(setDefaultShippingAddressId(response?.data?.data?.list?.find(item => item?.isDefault)?.id || ''))
      }
    }
  
    const fetchBillingAddressList = async () => {
      const response = await addressListService({
        getAll: true, 
        filter: 'Active', 
        type: 'Billing'
      });
      if(response?.data?.success){
        dispatch(setBillingAddressList(response?.data?.data?.list))
        dispatch(setDefaultBillingAddressId(response?.data?.data?.list?.find(item => item?.isDefault)?.id || ''))
      }
    }
    
    const handleRemoveItem = async () => {
        const response = await addressItemRemoveService({id: formValue?.id});
        if(response?.data?.success){
          if(addressType === "Shipping"){
            fetchShippingAddressList();
          }
          if(addressType === "Billing"){
            fetchBillingAddressList();
          }
          toggleRemoveModal();
        }
      }

    const closeBtn = (
        <button className="close" onClick={toggleRemoveModal} type="button">
        &times;
        </button>
    );

  return (
    <div className="remove__modal address__remove__modal">
        <h3>
            Are you sure, you want to remove the {addressType} address?
        </h3>
        
        <div className='button--group'>
          <button className="remove__primary__btn" onClick={handleRemoveItem}>
            Remove
          </button>{' '}
          <button className="remove__secondary__btn" onClick={toggleRemoveModal}>
            Back
          </button>
        </div>
    </div>
  );
}



export default RemoveAddressModal;