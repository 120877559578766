import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginModal: false,
  registerModal: false,
};
const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setLoginModalToggle: (state, action) => {
      state.loginModal = action.payload;
    },

    setRegisterModalToggle: (state, action) => {
      state.registerModal = action.payload;
    },
  },
});
export const { setLoginModalToggle, setRegisterModalToggle } =
  modalsSlice.actions;
export const selectLoginModalToggle = (state) => state.modals.loginModal;
export const selectRegisterModalToggle = (state) => state.modals.registerModal;
export default modalsSlice.reducer;
