import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetail: {},
  pcoinBalance: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setPCoinBalance: (state, action) => {
      state.pcoinBalance = action.payload;
    },
  },
});
export const { setUserDetail, setPCoinBalance } = userSlice.actions;

export const selectUserDetails = (state) => state.user.userDetail;
export const selectPcoinBalance = (state) => state.user.pcoinBalance;

export default userSlice.reducer;
