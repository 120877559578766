import React, { useEffect, useState, useRef } from "react";
import "../../assets/scss/base/pages/authentication/PhoneNumberVerification.scss";
import "../../assets/scss/base/pages/index.scss";
import toast, { Toaster } from "react-hot-toast";

import { phoneIcon } from "../../assets/images/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";

import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";

// api service
import {
  OTPValidationChangeMobileNumber,
  resendOTP,
} from "../../service/auth.service";
import { updateUserSession, getUser } from "../../utils/AuthService";

const OtpVerificationChangeMobileNo = () => {
  const navigate = useNavigate();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const user = getUser();
  const phone = user?.mobileNo;
  const { state } = useLocation();
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [otpSent, setOTPSent] = useState(false);

  const [formData, setFormData] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const [otpError, setOtpError] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const otpCode = `${formData.first}${formData.second}${formData.third}${formData.fourth}`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await OTPValidationChangeMobileNumber({
      otpType: "Mobile Number Verification",
      otp: otpCode,
    });

    const user = result?.data?.data?.user;
    if (result?.data?.success) {
      updateUserSession(user);
      navigate("/");
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <h4 style={{ marginTop: "0.5rem" }}>
              Your mobile number changed successfully.
            </h4>
            {/* <p>This product is successfully added to cart list</p> */}
            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else if (
      !result?.data?.success &&
      result?.data?.errorMessage === "Incorrect otp"
    ) {
      setFormData({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setOtpError("Incorrect otp");
    } else if (
      !result?.data?.success &&
      result?.data?.errorMessage === "OTP expired"
    ) {
      setFormData({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setOtpError("OTP expired");
    }
  };
  const calculateElapsedTime = () => {
    if (!lastRequestTime) return 0;
    const currentTime = new Date().getTime();
    return (currentTime - lastRequestTime) / 1000; // Convert to seconds
  };

  const startCountdown = () => {
    let timeLeft = 60 - Math.floor(calculateElapsedTime());
    setRemainingTime(timeLeft);

    const interval = setInterval(() => {
      timeLeft -= 1;
      if (timeLeft >= 0) {
        setRemainingTime(timeLeft);
        setOtpError(
          `Please wait for ${timeLeft} seconds before resending OTP.`
        );
      } else {
        clearInterval(interval);
        setOtpError("");
      }
    }, 3000); // Update every second
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    const timeLeft = Math.floor(calculateElapsedTime());
    if (timeLeft < 60) {
      startCountdown();
      return false;
    } else {
      const result = await resendOTP({
        otpType: "Mobile Number Verification",
      });

      if (result?.data.success) {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleBrokenIcon} alt="" />
              </div>
              <h4 style={{ marginTop: "0.5rem" }}>
                Otp sent succesfully to your mobile number.
              </h4>
              {/* <p>This product is successfully added to cart list</p> */}
              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
        setFormData({
          first: "",
          second: "",
          third: "",
          fourth: "",
        });
        setLastRequestTime(new Date().getTime());
        setOtpError("");
        setOTPSent(true); // Set to true after OTP has been successfully resent
      }
    }
  };
  useEffect(() => {
    setLastRequestTime(new Date().getTime());
  }, []);

  return (
    <div className="phone-number-verification">
      <div className="content3Otp">
        <div className="header1">
          <img className="featured-icon" alt="phone" src={phoneIcon} />
          <div className="text-and-supporting-text">
            <div className="text11">Check your Phone/E-mail</div>
            <div className="supporting-text">
              <p className="we-sent-a">{`We sent a verification code to `}</p>
              <p className="p">
                {" "}
                {/* {!isNaN(state?.phoneNumber) && "+6"} */}
                +6{state?.phoneNumber}
              </p>
            </div>
          </div>
        </div>
        <form>
          <div className="content4">
            <div className="verification-code-input-field">
              <div className="verification-code-input-field">
                <div className="input6">
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[0]}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[0 + 1].current.focus();

                      setFormData({ ...formData, first: e.target.value });
                    }}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[1]}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[1 + 1].current.focus();

                      setFormData({ ...formData, second: e.target.value });
                    }}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[2]}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }
                      inputRefs[2 + 1].current.focus();

                      setFormData({ ...formData, third: e.target.value });
                    }}
                    placeholder="-"
                    required
                  />
                  <input
                    className="mega-input-field-base"
                    type="number"
                    min={0}
                    max={9}
                    ref={inputRefs[3]}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.length > 1) {
                        e.target.value = inputValue.slice(0, 1); // Only keep the first digit
                      }

                      setFormData({ ...formData, fourth: e.target.value });
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props

                    placeholder="-"
                    required
                  />
                </div>
              </div>
            </div>
            <button onClick={handleSubmit} className="buttons3">
              <div className="text13">Verify</div>
            </button>
          </div>
          {otpError && (
            <p
              style={{ fontSize: "14px", color: "#D71513", textAlign: "left" }}
            >
              {otpError}
            </p>
          )}
        </form>

        <div className="row1">
          <div className="didnt-receive-the">Didn’t receive the Code?</div>
          <button onClick={handleResendOTP} className="buttons4Verification">
            <div className="text14">Click to resend</div>
          </button>
        </div>
        <button className="buttons5" onClick={() => navigate("/")}>
          <AiOutlineArrowLeft className="backarrow" />
          <div className="text15Verification">Back to log in</div>
        </button>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default OtpVerificationChangeMobileNo;
