/* eslint-disable no-undef */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LuEye, LuEyeOff } from "react-icons/lu";
import "../../assets/scss/base/pages/authentication/SignIn.scss";
import { logo } from "../../assets/images/authentication/index";
import "../../assets/scss/base/pages/index.scss";
import xClose from "../../assets/images/homepage/xClose.svg";

// api service
import { loginUser } from "../../service/auth.service";

// utility service
import { setTokenExpireTime, setUserSession } from "../../utils/AuthService";

// Redux-Settings
import { setLogin } from "../../redux/authSlice";
import { Modal } from "reactstrap";
import {
  selectLoginModalToggle,
  setLoginModalToggle,
  setRegisterModalToggle,
} from "../../redux/modalsSlice";
const siteKey = process.env.reCAPTCHA_site_key;

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector(selectLoginModalToggle);
  // const onSignInContainerClick = () => {
  //   navigate("/");
  // };

  const [formData, setFormData] = useState({
    phone: "",
    password: "",
  });

  const [togglePassword, setTogglePassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState("");

  const [phoneLengthError, setPhoneLengthError] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState("");
  const [notValidated, setNotValidated] = useState(false);
  const [isPlusSixToShow, setIsPlusSixToShow] = useState(false);

  const [loading, setLoading] = useState(false);

  /**
   * @function handleNumberInput
   * @param event
   * @description This function is used to save the phone number
   */
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleNumberInput = (e) => {
    const phoneValue = e?.target?.value;
    setPasswordError("");
    setWrongCredentials("");
    setPasswordError("");
    setPhoneLengthError(false);
    setFormData({
      ...formData,
      phone: phoneValue,
    });
    if (!isNaN(e.target.value)) {
      setIsPlusSixToShow(true);
      if (phoneValue.length >= 9 && phoneValue.length <= 11) {
        setFormData({
          ...formData,
          phone: phoneValue.startsWith(0) ? phoneValue : 0 + phoneValue,
        });
        setPhoneInputError("");
      } else {
        setFormData({
          ...formData,
          phone: phoneValue,
        });
        setPhoneInputError("Phone number must be between 9 and 11 digits.");
      }
      if (phoneValue.length < 1) {
        setPhoneInputError("");
      }
    } else {
      setPhoneInputError("");
      setIsPlusSixToShow(false);

      if (!isValidEmail(phoneValue)) {
        setNotValidated(true);
      } else {
        setNotValidated(false);
      }
    }
    if (!phoneValue) {
      setNotValidated(false);
      setIsPlusSixToShow(false);
    }
  };

  /**
   * @function handlePasswordInput
   * @param event
   * @description This function is used to save the password
   */

  const handlePasswordInput = (e) => {
    setPasswordError("");
    setWrongCredentials("");
    setFormData({
      ...formData,
      password: e.target.value,
    });
  };

  /**
   * @function handleSubmit
   * @param
   * @description This function is used to save the password
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData?.phone?.length > 0) {
      setLoading(true);
    }

    if (formData.phone?.length < 1) {
      setPhoneLengthError(true);
      return false;
    }
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LelLbYqAAAAAP4I1VJDijFQg-Z9pnQznqPSgmDf", {
          action: "submit",
        })
        .then(async function (token) {
          // Add your logic to submit to your backend server here.
          const result = await loginUser({
            emailOrMobile: formData.phone,
            password: formData.password,
            userType: "user",
            recaptchaResponse: token,
          });
          if (result?.data?.success) {
            if (result?.data?.data?.user?.password) {
              setLoading(false);

              const tokens = {
                foodAccessToken: result?.data?.data?.foodTokens?.access?.token,
                foodRefreshToken:
                  result?.data?.data?.foodTokens?.refresh?.token,
                tokenAccess: result?.data?.data?.tokens?.access?.token,
                tokenRefresh: result?.data?.data?.tokens?.refresh?.token,
              };

              const user = result?.data?.data?.user;

              setUserSession(tokens, user);

              setTokenExpireTime(
                result?.data?.data?.tokens?.access?.expires,
                result?.data?.data?.foodTokens?.access?.expires
              );
              dispatch(setLogin(true));
              dispatch(setLoginModalToggle(false));
              navigate("/");
            } else {
              const token = result?.data?.data?.tokens;
              const tokens = {
                tokenAccess: token,
              };
              setUserSession(tokens);
              dispatch(setLoginModalToggle(false));
              navigate(`/verification/`, {
                state: {
                  type: "Account Verification",
                  btnText: "Verify",
                  phoneNumber: formData.phone,
                },
              });
            }
          } else {
            if (
              result?.data?.errorMessage.includes(
                "Your Account Verification otp is already sent"
              )
            ) {
              dispatch(setLoginModalToggle(false));

              navigate(`/verification/`, {
                state: {
                  type: "Account Verification",
                  btnText: "Verify",
                  phoneNumber: formData.phone,
                },
              });
            }
            setPasswordError(result?.data?.errorMessage);
            setLoading(false);
          }
        });
    });
  };

  const handleShowPasswordOne = () => {
    setTogglePassword(!togglePassword);
  };

  const handleForgotPasswordClick = () => {
    dispatch(setLoginModalToggle(false));

    navigate("/forgotpassword");
  };

  const handleRegisterClick = () => {
    dispatch(setLoginModalToggle(false));
    navigate("/register");
  };

  return (
    <Modal isOpen={isOpen}>
      <img
        src={xClose}
        alt="cross-Tcon"
        className="cross___icon"
        onClick={() => dispatch(setLoginModalToggle(false))}
      />
      <div className="sign-in">
        <div className="contentSignIn">
          <div className="headerSignIn">
            <img className="logo-1-icon" alt="logo" src={logo} />
            <div className="text3">Sign In</div>
          </div>
          <form className="content1SignIn">
            <div className="formSignIn">
              <div className="input-field">
                <div className="input-field">
                  <div className="input__Head">Phone number/E-mail</div>
                  <div className="phone-div">
                    {/* <input className="prefix-phone" value="+6" /> */}
                    {isPlusSixToShow && (
                      <span className="prefix-phone">+6</span>
                    )}
                    <input
                      // style={{
                      //   borderLeft: "1px solid #d0d5dd",
                      //   borderRadius: "8px",
                      // }}
                      className={isPlusSixToShow ? "input9 phone" : "input2"}
                      type="text"
                      onChange={(e) => handleNumberInput(e)}
                      placeholder="Enter your phone number or e-mail"
                      required
                    />
                  </div>
                  {notValidated ? (
                    <span className="hint-text4-email">
                      Enter valid email address
                    </span>
                  ) : null}
                  {phoneInputError && (
                    <span className="confirm-password-check-red">
                      {phoneInputError}
                    </span>
                  )}
                  {phoneLengthError && (
                    <span className="confirm-password-check-red">
                      Phone number must not empty
                    </span>
                  )}
                </div>
              </div>

              <div className="input-field">
                <div className="input-field">
                  <div className="input__Head">Password</div>
                  <div className="pass-div">
                    <input
                      className="input2"
                      type={togglePassword ? "text" : "password"}
                      onChange={(e) => handlePasswordInput(e)}
                      placeholder="Enter password here"
                    />

                    {togglePassword ? (
                      <LuEyeOff
                        className="eye-icon-1"
                        onClick={handleShowPasswordOne}
                      />
                    ) : (
                      <LuEye
                        className="eye-icon-1"
                        onClick={handleShowPasswordOne}
                      />
                    )}
                  </div>

                  {passwordError && (
                    <span className="confirm-password-check-red">
                      {passwordError}
                    </span>
                  )}
                  {wrongCredentials && (
                    <span className="confirm-password-check-red">
                      {wrongCredentials}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                style={{
                  color: "#D71513",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  textDecoration: "Underline",
                  cursor: "Pointer",
                }}
                onClick={() => {
                  navigate("/FAQ");
                  dispatch(setLoginModalToggle(false));
                }}
              >
                Can't Sign In?
              </p>
              <div onClick={handleForgotPasswordClick} className="rowSignIn">
                <div className="text4">Forgot password?</div>
              </div>
            </div>
            {!loading ? (
              <button
                type="submit"
                onClick={handleSubmit}
                className="actionsSignIn"
              >
                <div className="buttons1SignIn">
                  <div className="text5">Sign in</div>
                </div>
              </button>
            ) : (
              <button className="actionsSignIn disabled">
                <div className="buttons1SignIn">
                  <div className="text5">
                    Sign in
                    <div className="loader-btn" />
                  </div>
                </div>
              </button>
            )}
          </form>
          <div className="row1SignIn">
            <div className="dont-have-an">Don’t have an account?</div>
            <div className="buttons2SignIn">
              <button onClick={handleRegisterClick} className="text6SignIn">
                Register Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignIn;
