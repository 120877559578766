import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import '../../assets/scss/base/pages/cart/OrderedItemsList.scss';
import "../../assets/scss/base/pages/SingleOngoingOrder/OrderedItem.scss";

import { priceCalculator } from "../../utils/Utility";
import ReactStars from "react-rating-stars-component";
import { AiFillStar } from "react-icons/ai";
import { selectPCoinValue } from "../../redux/utilitySlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const OrderedItem = ({
  orderedItem,
  setOpenModal,
  deliverystatus,
  key,
  setSelectedProduct,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const perPCoinValue = useSelector(selectPCoinValue);

  const handleOpenModal = (event, key) => {
    // eslint-disable-next-line no-undef
    event.stopPropagation();
    setOpenModal(true);
    setSelectedProduct(orderedItem);
  };
  let isDelivered = deliverystatus;

  return (
    <div>
      <div>
        <div
          className="frame-parent11OrderedItem"
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => navigate(`/product/${orderedItem?.Product?.slug}`)}
        >
          <div className="checkbox-base-parentOrderedItemsList">
            <div className="items-selectedOrderedItemsList">
              {/* {selectedCartItemsId?.length} / 
          {cartItems?.length} items selected */}
            </div>
          </div>
          <div className="frame-parent14OrderedItemsList">
            <div
              className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
              // onClick={() => {
              //   navigate('/product-details',{state:{productId: cartItem?.Product?.id}})
              // }}
            >
              <img
                className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                alt="item"
                src={`${imageCDN}/${orderedItem?.Product?.thumbnail}`}
              />
            </div>

            <div className="frame-parent15OrderedItemsList">
              <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
                <div
                  // onClick={() => {
                  //   navigate('/product-details',{state:{productId: cartItem?.Product?.id}})
                  // }}
                  className="hp-pavilion-14-dv2029tuOrderedItemsList"
                >
                  {orderedItem?.Product?.name}
                </div>
                <div
                  className="parentOrderedItemsList"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className="rm290900OrderedItemsList"
                    style={{
                      display: "flex",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    <p style={{ fontSize: "11px" }}>RM</p>
                    {Number(
                      priceCalculator({
                        price: orderedItem?.price,
                        discountPrice: orderedItem?.discount,
                      })
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  {orderedItem?.discount > 0 && (
                    <div
                      className="divOrderedItemsList"
                      style={{ fontSize: "11px", marginTop: "2px" }}
                    >
                      {orderedItem?.discount > 0 &&
                        "RM" +
                          orderedItem?.price.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </div>
                  )}

                  {orderedItem?.discount > 0 && (
                    <div
                      className="offOrderedItemsList"
                      style={{ fontSize: "11px", marginTop: "2px" }}
                    >
                      {Math.floor(orderedItem?.discount)}% OFF
                    </div>
                  )}
                </div>
              </div>

              <div className="frame-parent13OrderedItemsList">
                {orderedItem?.ProductVariation?.attibutes?.Color !==
                  "Default" &&
                  orderedItem?.ProductVariation?.attibutes?.Color !==
                    "other" && (
                    <div className="coloringDivOrderedItemsList">
                      <p className="colorTitleOrderedItemsList">Color:</p>
                      <div
                        style={{
                          border: "2px solid #d3d3d3",
                          height: 20,
                          width: 20,
                          borderRadius: 20,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: 15,
                            width: 15,
                            borderRadius: 20,
                            backgroundColor: orderedItem?.ProductVariation
                              ?.attibutes?.Color
                              ? orderedItem?.ProductVariation?.attibutes?.Color
                              : orderedItem?.ProductVariation?.attibutes?.color,
                          }}
                        />
                      </div>
                      <p style={{ textTransform: "capitalize" }}>
                        {orderedItem?.ProductVariation?.attibutes?.Color
                          ? orderedItem?.ProductVariation?.attibutes?.Color
                          : orderedItem?.ProductVariation?.attibutes?.color}
                      </p>
                    </div>
                  )}
                {(orderedItem?.ProductVariation?.attibutes?.storage ||
                  orderedItem?.ProductVariation?.attibutes?.Size) && (
                  <div className="coloringDivOrderedItemsList">
                    <p className="colorTitleOrderedItemsList">Size:</p>
                    <p>
                      {orderedItem?.ProductVariation?.attibutes?.storage &&
                        orderedItem?.ProductVariation?.attibutes?.storage}
                      {orderedItem?.ProductVariation?.attibutes?.Size &&
                        orderedItem?.ProductVariation?.attibutes?.Size + " "}
                    </p>
                  </div>
                )}
              </div>
              <div className="OrderedItem__Inner__Container">
                <div
                  className="quantity-1-parentOrderedItemsList"
                  style={{ marginTop: "0.3rem" }}
                >
                  <div className="blackOrderedItemsList">
                    Quantity :
                    {/* <span className="quantity__controller" onClick={() => handleFieldChange('quantity', index, (Number(cartItem?.quantity) === 1 ? cartItem?.quantity : Number(cartItem?.quantity) - 1), cartItem?.id)}>-</span> */}
                    <span className="product__quantity">
                      {orderedItem?.quantity}
                    </span>
                    {/* <span className="quantity__controller" onClick={() => handleFieldChange('quantity', index, (Number(cartItem?.quantity) + 1), cartItem?.id)}>+</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "0.1rem" }}>
                  <p>
                    SKU :{" "}
                    <span style={{ color: "grey" }}>
                      {orderedItem?.ProductVariation?.sku}
                    </span>
                  </p>
                </div>
                <div style={{ marginTop: "0.1rem" }}>
                  <p>
                    TOTAL :{" "}
                    <span style={{ color: "grey" }}>
                      RM{" "}
                      {Number(
                        orderedItem?.discount > 0
                          ? priceCalculator({
                              price: orderedItem?.price,
                              discountPrice: orderedItem?.discount,
                            }) * orderedItem?.quantity
                          : orderedItem?.price * orderedItem?.quantity
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <p
                  style={{
                    color: "#d71513",
                    fontWeight: "500",
                    marginTop: "0.2rem",
                  }}
                >
                  Redeemable P-coins :{" "}
                  {priceCalculator({
                    price: orderedItem?.price,
                    discountPrice: orderedItem?.discount,
                  }) *
                    perPCoinValue <
                  orderedItem?.ProductVariation?.redeemPcoinsLimit
                    ? parseFloat(
                        priceCalculator({
                          price: orderedItem?.price,
                          discountPrice: orderedItem?.discount,
                        }) * perPCoinValue
                      ) * orderedItem?.quantity
                    : orderedItem?.ProductVariation?.redeemPcoinsLimit *
                      orderedItem?.quantity}
                </p>
              </div>
            </div>
          </div>
        </div>
        {isDelivered && (
          <>
            <div className="OrderCard__BottomCont">
              {orderedItem?.rating !== null ? (
                <ReactStars
                  count={5}
                  value={orderedItem?.rating}
                  size={24}
                  edit={false}
                  emptyIcon={<AiFillStar />}
                  halfIcon={<AiFillStar />}
                  fullIcon={<AiFillStar />}
                  activeColor="#168952"
                />
              ) : (
                <p
                  style={{
                    color: "#d71513",
                    marginTop: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleOpenModal(e, key)}
                >
                  <u>Rate & Review this product now</u>
                </p>
              )}
              {/* <hr className="OrderCard__TopBottomBorder" /> */}

              {/* <p className="OrderCard__BottomRight">Reorder</p> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderedItem;
