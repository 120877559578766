import React, {useState, useMemo, useEffect} from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from "react-redux";

import { getUser } from '../../../utils/AuthService';
// Images

import { pcoin } from '../../../assets/images/homepage';
import ErrorIcon from "../../../assets/images/homepage/Error-close-icon.png"
import Successicon from "../../../assets/images/homepage/check-icon.svg"

function PCoinReddemModal({PCoinReddemModaltoggle,isOpenPCoinReddemModal, pCoinModalData}) {
  const closeBtn = (
    <button className="close" onClick={PCoinReddemModaltoggle} type="button">
      &times;
    </button>
  );

  return (
    <div>
      <Modal 
      className='PCoinRedeem__modal addrees__modal' 
      backdropClassName='light__modal__background'
      isOpen={isOpenPCoinReddemModal} 
      toggle={PCoinReddemModaltoggle} 
      size='md'
      centered
      >
         <ModalHeader toggle={PCoinReddemModaltoggle} close={closeBtn} />
        <ModalBody>
            {pCoinModalData?.isSuccess &&
             <div className='PCoinRedeem__modal__block'>
                <div className='PCoinRedeem__modal__block--img'>
                  <img src={Successicon} alt="" />
                </div>
                <h3>Successfully Redeemed</h3>
                <div className='PCoinRedeem__modal__block--coins'>
                  <img src={pcoin} alt="" />
                  <p>
                    {pCoinModalData?.userInputPCoin} P-Coins
                  </p>
                </div>
            </div>}
            {!pCoinModalData?.isSuccess &&
             <div className='PCoinRedeem__modal__block'>
                <div className='PCoinRedeem__modal__block--img'>
                  <img src={ErrorIcon} alt="" />
                </div>
                <h3>Redemption Failed</h3>
                <div className='PCoinRedeem__modal__block--error--content'>
                  <p className='PCoinRedeem__modal__block--content'>
                    Something went wrong
                    Please check your P-Coins balance to redeem
                  </p>
                  <div className='PCoinRedeem__modal__block--coins'>
                    <img src={pcoin} alt="" />
                    <p>
                      {pCoinModalData?.userInputPCoin} P-Coins
                    </p>
                  </div>
                </div>
            </div>}
        </ModalBody>
      </Modal>
    </div>
  );
}



export default PCoinReddemModal;