/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import WishlistCard from "../../components/wishlist/wishlistCard";
import "../../assets/scss/base/pages/wishlistPage/wishlistPage.scss";
import EmptyBanner from "../../assets/images/wishlist/EmptyBanner.png";
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";
import { wishlistService } from "../../service/wishlist.service";
import { setWishList, selectWishList } from "../../redux/wishlistSlice";
import { LoaderIcon } from "react-hot-toast";
import Loader from "../../components/micro/loader/Loader";
import { Helmet } from "react-helmet";

function wishlistPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishListItems = useSelector(selectWishList);
  const [isLoading, setIsLoading] = useState(true);
  const [successFullyRemoved, setSuccessFullyRemoved] = useState(false);

  const fetchWishlist = async () => {
    const response = await wishlistService({ filter: "" });
    setIsLoading(false);

    if (response?.data?.success) {
      dispatch(setWishList(response?.data?.data?.list));
    }
  };
  useEffect(() => {
    fetchWishlist();
  }, [ successFullyRemoved]);
  return (
    <div className="wishlist__Page">
      <Helmet>
                <meta charSet="utf-8" />
                <title >Wishlist</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <span className="wishlistPage__Head">My Wishlist</span>{" "}
      <span className="wishlistPage__ItemCount">
        {wishListItems?.length} items
      </span>
      {isLoading ? (
        <Loader />
      ) : wishListItems?.length > 0 ? (
        <div className="WishlistCard__Container">
          {wishListItems?.map((item, index) => {
            return (
              <WishlistCard
                key={index}
                item={item}
                setSuccessFullyRemoved={setSuccessFullyRemoved}
              />
            );
          })}
        </div>
      ) : (
        <div className="wishlistPage_EmptyContainer">
          <img src={EmptyBanner} alt="Banner" />
          <h1 className="wishlistPage__EmptyHead">Your wishlist is empty</h1>
          <p className="wishlistPage__EmptyDesc">
            Save items that you like in your wishlist. Review them anytime and
            easily move them to the cart
          </p>
          <button
            className="wishlistPage__EmptyBtn"
            onClick={() => navigate("/")}
          >
            Home
          </button>
        </div>
      )}
    </div>
  );
}

export default withLayout(wishlistPage, {
  isProductPage: false,
  isNestedPageName: false,
});
