import React, { useState } from "react";
//images
import pCoin from "../../assets/images/account/DailyLoginBonus/P-Coin.png";
//styles
import "../../assets/scss/base/pages/account/DailyLoginBonus/DailyLoginBonus.scss";
import { bonusClaimService } from "../../service/user.service";
import SuccessModal from "./Modal/SuccessModal";

const DailyLoginBonus = ({
  BonusPerDay,
  loginBonuses,
  setSuccessfullyUpdated,
}) => {
  const [isLoading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextDate, setNextDate] = useState("");
  const handleBonusClaim = async (id) => {
    setIsLoading(true);
    const result = await bonusClaimService(id);

    if (result.data.data.message == "Done") {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  };
  const date = new Date();
  const today = `${date.getDate() < 10 ? 0 : ""}${date.getDate()}-${
    date.getMonth() < 10 ? 0 : ""
  }${date.getMonth() + 1}-${date.getFullYear()}`;
  //Taking second date

  //function is to get previous day bonus is claimed or not
  const getPreviousDayBonusStatus = () => {
    let result = {
      isClaimed: "",
      nextDay: "",
    };
    loginBonuses?.forEach((item, index) => {
      if (item?.date === today && item?.status === "Claimed") {
        result.isClaimed = true;
        result.nextDay = loginBonuses[index + 1].date;
      }
    });

    return result;
  };
  const { nextDay, isClaimed } = getPreviousDayBonusStatus();
  return (
    <>
      <div
        className={
          BonusPerDay?.status !== "Claimed"
            ? today === BonusPerDay?.date
              ? "DailyBonus__Hover__Claim__Card"
              : nextDay === BonusPerDay?.date &&
                getPreviousDayBonusStatus().isClaimed
              ? "DailyBonus__Hover__Claimed__Card"
              : "DailyBonus__Card"
            : "DailyBonus__Card__Claimed"
        }
        // onMouseEnter={() => today === BonusPerDay?.date && setActiveClaim(true)}
        // onMouseLeave={() =>
        //   today === BonusPerDay?.date && setActiveClaim(false)
        // }
      >
        <div>
          <h5>Day {BonusPerDay?.day}</h5>

          <p className="DailyBonus__Card__Text">
            {BonusPerDay?.status === "Claimed"
              ? "Earned"
              : nextDay === BonusPerDay?.date &&
                getPreviousDayBonusStatus().isClaimed
              ? "Come Back tomorrow to get"
              : `Check in on  ${
                  today === BonusPerDay?.date
                    ? "today"
                    : "day" + " " + BonusPerDay?.day + " " + "to get"
                }`}{" "}
            <img src={pCoin} alt="pCoin" style={{ width: "20px" }} />
            {BonusPerDay?.bonus} P-coin
          </p>
          {BonusPerDay?.status !== "Claimed" && today === BonusPerDay?.date && (
            <button
              className="DailyBonus__Card__Btn"
              onClick={() => handleBonusClaim(BonusPerDay?.day)}
            >
              {isLoading ? "Claiming.." : "Claim"}
            </button>
          )}
          {BonusPerDay?.status !== "Claimed" &&
            nextDay === BonusPerDay?.date &&
            getPreviousDayBonusStatus().isClaimed && (
              <button className="DailyBonus__Card__BtnClaimed">Claim</button>
            )}
        </div>
        <div className="DailyLoginBonus__Left__Container">
          <img src={pCoin} alt="pCoin" style={{ width: "20px" }} />
          <span>{BonusPerDay?.bonus}</span>
        </div>
      </div>
      {isModalOpen && (
        <SuccessModal
          Day={BonusPerDay?.day}
          PCoin={BonusPerDay?.bonus}
          isOpenSuccesModal={isModalOpen}
          setIsOpenSuccessModal={setIsModalOpen}
          setSuccessfullyUpdated={setSuccessfullyUpdated}
        />
      )}
    </>
  );
};

export default DailyLoginBonus;
