/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import PCoin from "../../assets/images/account/Order/PCoin.svg";
import chevronRight from "../../assets/images/account/Menu/chevronRight.svg";

// styles
import "../../assets/scss/base/pages/account/Order/Order.scss";

//Utility

import RateReviewModal from "./Modal/RateReviewModal";
import { priceCalculator } from "../../utils/Utility";

//url
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const Order = ({ item }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const orderItem = item.OrderItems.length;
  return (
    <>
      {orderItem > 0 && (
        <div
          className="OrderCard"
          onClick={() => navigate(`/account/order/${item?.id}`)}
        >
          <p style={{ fontWeight: "600", marginBottom: "1rem" }}>
            Order Id : #PC000{item?.id}
          </p>
          <div className="OrderCard__TopContainer">
            <div className="OrderCard__TopRightContainer">
              <div
                className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
              // onClick={() => {
              //   navigate('/product-details',{state:{productId: cartItem?.Product?.id}})
              // }}
              >
                <img
                  src={`${imageCDN}/${item?.OrderItems[0]?.Product?.thumbnail}`}
                  alt="Product_Banner"
                  className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                />
              </div>

              <div>
                {item?.status == "Initiated" && (
                  <h3 className="OrderCard__Header">
                    Will be delivered by,
                    {item?.deliveryDate &&
                      moment
                        .unix(Number(item?.deliveryDate))
                        .format("MMM Do YYYY")}
                  </h3>
                )}
                <p className="Ordercard__Title">
                  {item?.OrderItems[0]?.Product?.name}
                </p>
                <p className="OrderCard__PriceTag">
                  <p style={{ fontSize: "11px", marginTop: "4px" }}>RM</p>
                  {/* {item?.OrderItems[0]?.price.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} */}
                  {Number(
                    priceCalculator({
                      price: item?.OrderItems[0]?.price,
                      discountPrice: item?.OrderItems[0]?.discount,
                    })
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <div className="OrderCard__Details">
                  {item?.OrderItems[0]?.ProductVariation?.attibutes?.Color !==
                    "Default" &&
                    item?.OrderItems[0]?.ProductVariation?.attibutes?.Color !==
                    "other" && (
                      <div className="OrderCard__Desc">
                        <span className="Product__ColorTitle">Color</span>:{" "}
                        <div className="Product__Color">
                          <div
                            style={{
                              border: "2px solid #d3d3d3",
                              height: 20,
                              width: 20,
                              borderRadius: 20,
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                height: 16,
                                width: 16,
                                borderRadius: 20,
                                backgroundColor: item?.OrderItems[0]
                                  ?.ProductVariation?.attibutes?.Color
                                  ? item?.OrderItems[0]?.ProductVariation?.attibutes?.Color?.toLowerCase()
                                  : item?.OrderItems[0]?.ProductVariation?.attibutes?.color?.toLowerCase(),
                              }}
                            />
                          </div>
                          <span style={{ textTransform: "capitalize" }}>
                            {item?.OrderItems[0]?.ProductVariation?.attibutes
                              ?.Color
                              ? item?.OrderItems[0]?.ProductVariation?.attibutes
                                ?.Color
                              : item?.OrderItems[0]?.ProductVariation?.attibutes
                                ?.color}
                          </span>
                        </div>
                      </div>
                    )}
                  {(item?.OrderItems[0]?.ProductVariation?.attibutes?.storage ||
                    item?.OrderItems[0]?.ProductVariation?.attibutes?.Size) && (
                      <div className="OrderCard__Desc">
                        <span className="Product__SizeTitle">Size</span>:
                        <span className="Product__Size">
                          {" "}
                          {item?.OrderItems[0]?.ProductVariation?.attibutes
                            ?.storage
                            ? item?.OrderItems[0]?.ProductVariation?.attibutes
                              ?.storage
                            : item?.OrderItems[0]?.ProductVariation?.attibutes
                              ?.Size &&
                            item?.OrderItems[0]?.ProductVariation?.attibutes
                              ?.Size + " "}{" "}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="OrderCard__InnerLeftContainer">
              <img src={chevronRight} alt="Arrow" />
              <p className="OrderCardDesc__Coin">
                <img src={PCoin} alt="Coin" />
                {item?.earnedPcoin}
              </p>
            </div>
          </div>
          <div className="OrderCard__Bottom__Container">
            <div>
              <span style={{ fontFamily: "Poppins", fontSize: "15px" }}>
                Order Status
              </span>{" "}
              :{" "}
              <span
                className={
                  item?.status == "Payment Failed" || item?.status == "Canceled"
                    ? "Order__status"
                    : "Order__Status__In__Green"
                }
              >
                {item?.status == "Initiated" ? "Payment Success" : item?.status}
              </span>
            </div>
            {item?.OrderItems?.length > 1 && (
              <p className="OrderCard__More__Btn">
                +{item?.OrderItems?.length - 1} more Product
              </p>
            )}
          </div>

          {/* {isDelivered && (
        <>
          <hr className="OrderCard__TopBottomBorder" />
          <div className="OrderCard__BottomCont">
            <p className="OrderCard__BottomLeft">
              Rate & Review this product now
            </p>
            <ReactStars
                        count={5}
                        value={3}
                        size={24}
                        edit={false}
                        emptyIcon={<AiFillStar/>}
                        halfIcon={<AiFillStar/>}
                        fullIcon={<AiFillStar/>}
                        activeColor="#168952"
                    />
            <p className="OrderCard__BottomRight">Reorder</p>
          </div>
        </>
      )} */}
          {openModal && (
            <RateReviewModal orderedItem={item} isOpen={openModal} />
          )}
        </div>
      )}
    </>
  );
};

export default Order;
