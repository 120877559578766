// addressListSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippingAddressList: [],
  billingAddressList: [],
  filteredList: [],
  pageCount: 0,
  totalItem: 0,
  defaultShippingAddressId: "",
  defaultBillingAddressId: "",
  zipcode: "",
  isAsDefaultAsShippingAddress: true,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setShippingAddressList: (state, action) => {
      state.shippingAddressList = action.payload;
    },
    setBillingAddressList: (state, action) => {
      state.billingAddressList = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setTotalItem: (state, action) => {
      state.totalItem = action.payload;
    },
    setIsAsDefaultAsShippingAddress: (state, action) => {
      state.isAsDefaultAsShippingAddress = action.payload;
    },
    setDefaultShippingAddressId: (state, action) => {
      state.defaultShippingAddressId = action.payload;
    },
    setSelectedAddressZipCode: (state, action) => {
      state.zipcode = action.payload;
    },

    setDefaultBillingAddressId: (state, action) => {
      state.defaultBillingAddressId = action.payload;
    },
  },
});

export const {
  setShippingAddressList,
  setBillingAddressList,
  setFilteredList,
  setPageCount,
  setTotalItem,
  setIsAsDefaultAsShippingAddress,
  setDefaultShippingAddressId,
  setSelectedAddressZipCode,
  setDefaultBillingAddressId,
} = addressSlice.actions;

export const selectShippingAddressList = (state) =>
  state.address.shippingAddressList;
export const selectBillingAddressList = (state) =>
  state.address.billingAddressList;
export const selectFiltered = (state) => state.address.filteredList;
export const selectPageCount = (state) => state.address.pageCount;
export const selectTotalItem = (state) => state.address.totalItem;
export const selectIsAsDefaultAsShippingAddress = (state) =>
  state.address.isAsDefaultAsShippingAddress;
export const selectAddressZipcode = (state) => state.address.zipcode;
export const selectDefaultShippingAddressId = (state) =>
  state.address.defaultShippingAddressId;
export const selectDefaultBillingAddressId = (state) =>
  state.address.defaultBillingAddressId;

export default addressSlice.reducer;
