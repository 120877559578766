import React from "react";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";
import { useDispatch } from "react-redux";

// API-Service
import {
  cartItemRemoveService,
  cartListService,
} from "../../../service/cart.service";

// Redux-Settings
import {
  setCartList,
} from "../../../redux/cartSlice";

function RemoveCartModal({
  toggle,
  isOpenModal,
  formValue,
  setIsRemovedFromCart,
}) {
  const removeCartItem = async () => {
    const response = await cartItemRemoveService({ id: formValue?.id });
    if (response?.data?.success) {
      setIsRemovedFromCart(`${formValue?.id}` + true);
      toggle();
    }
  };

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      {/* &times; */}
    </button>
  );

  return (
    <div>
      <Modal
        className="remove__modal"
        isOpen={isOpenModal}
        toggle={toggle}
        backdropClassName="light__modal__background"
      >
        <ModalHeader toggle={toggle} close={closeBtn}>
          Are you sure, you want to remove the cart item?
        </ModalHeader>

        <ModalFooter>
          <button className="remove__primary__btn" onClick={removeCartItem}>
            Remove
          </button>{" "}
          <button className="remove__secondary__btn" onClick={toggle}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default RemoveCartModal;
