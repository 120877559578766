import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux-Settings
import { setIsLoginPopup, selectIsLoginPopup } from '../../redux/utilitySlice';
// Images
import PlaceOrderSuccessThumb from "../../assets/images/homepage/password-reset.png"

function LoginMessageModal() {
  const navigate = useNavigate();
  const isLoginMessageModal = useSelector(selectIsLoginPopup)
  const dispatch = useDispatch();

    const LoginMessageModalToggle = () => {
      dispatch(setIsLoginPopup(!isLoginMessageModal))
    }

    const closeBtn = (
        <button className="close" onClick={LoginMessageModalToggle} type="button">
          &times;
        </button>
    );

  return (
    <div>
      <Modal 
      className='orderPlace__success__Modal addrees__modal' 
      isOpen={isLoginMessageModal} 
      toggle={LoginMessageModalToggle}  
      backdropClassName='light__modal__background'
      >
        <ModalHeader toggle={LoginMessageModalToggle} close={closeBtn} />
        <ModalBody>
            <img src={PlaceOrderSuccessThumb} alt="" />
            <div className='orderPlace__success__Modal__content'>
              <h3 className='login__modal__message'>
                Kindly login to use this functionality
              </h3>
              <button onClick={() => {
                navigate('/login')
              }}>
                Login 
              </button>
            </div>
        </ModalBody>
       
      </Modal>
    </div>
  );
}



export default LoginMessageModal;