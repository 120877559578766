import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { MdClear } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import { LuEye, LuEyeOff } from "react-icons/lu";
import toast, { Toaster } from "react-hot-toast";
import CheckCircleBrokenIcon from "../../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../../assets/images/homepage/x-close.svg";

import "../../../assets/scss/base/pages/account/DeleteAccModal/DeleteAccModal.scss";
import { deleteProfileService } from "../../../service/user.service";
import { removeUserSession } from "../../../utils/AuthService";
import { setUserDetail } from "../../../redux/userSlice";
import {
  setCartList,
  setSelectedCartItemsId,
  setSelectedCartItemsList,
} from "../../../redux/cartSlice";
import {
  setNotificationList,
  setPages,
  setTotalNotification,
} from "../../../redux/notificationSlice";
import { setLogin, setLogout } from "../../../redux/authSlice";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

function DeleteAccModal({
  setOpenDeleteAccModal,
  openDeleteAccModal,
  username,
  mobileNo,
  Email,
  ProfileImage,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordEnterModal, setPasswordEnterModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteProfile = async (e) => {
    setIsLoading(true);
    const response = await deleteProfileService({
      password: password,
    });
    if (response.data.success) {
      setIsLoading(false);
      setError(null);
      dispatch(setLogout(false));
      removeUserSession();
      dispatch(setUserDetail(" "));
      dispatch(setCartList([]));
      dispatch(setSelectedCartItemsList([]));
      dispatch(setSelectedCartItemsId([]));
      dispatch(setNotificationList([]));
      dispatch(setPages(0));
      dispatch(setTotalNotification(0));
      navigate("/");
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully deleted your profile!</h4>
              <p>Your profile is successfully deleted</p>
            </div>
            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      setIsLoading(false);
      setError(response.data.errorMessage);
    }
  };
  return (
    <Modal isOpen={openDeleteAccModal}>
      <MdClear
        className="Delete__Modal__Cross__Icon"
        onClick={() => {
          setOpenDeleteAccModal(false);
        }}
      />
      <ModalBody style={{ padding: "0rem 1.5rem 1.5rem 1.5rem" }}>
        <div>
          <h4 className="DeleteAcc__Modal__Heading">Delete Account</h4>
          {!isPasswordEnterModal ? (
            <div>
              <p className="DeleteAcc__Modal__Para">
                Are you sure you want to delete this account?
              </p>
              <div className="UserDetail__Block">
                {ProfileImage ? (
                  <img
                    src={`${url}/${ProfileImage}`}
                    alt="user-Icon"
                    className="Profile__Image"
                  />
                ) : (
                  <GoPerson className="dropdown-iconAccount" />
                )}

                <p className="UserDetail__Para">{username}</p>
                <p className="UserDetail__Text">{Email}</p>
                <p className="UserDetail__Text">+6 {mobileNo}</p>
              </div>
            </div>
          ) : (
            <div>
              <p className="DeleteAcc__Modal__Para">
                Enter your password to delete the account.
              </p>
              <p className="Input__Field__Label">Password</p>
              <div className="Input__Container">
                <input
                  type={!showPassword ? "password" : "text"}
                  className={error ? "Error__Field" : "Input__Field"}
                  placeholder="Enter password here"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <span style={{ color: "#d71513" }}>{error}</span>}

                <div className="Input__Eye__Icon">
                  {!showPassword ? (
                    <span
                      onClick={() => setShowPassword(true)}
                      style={{ cursor: "Pointer" }}
                    >
                      <LuEye />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowPassword(false)}
                      style={{ cursor: "Pointer" }}
                    >
                      <LuEyeOff />
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <button
            className="DeleteAcc__Modal__Delete__Btn"
            onClick={(e) =>
              !isPasswordEnterModal
                ? setPasswordEnterModal(true)
                : handleDeleteProfile(e)
            }
          >
            {isLoading ? "Loading..." : "Delete"}
          </button>
          {!isPasswordEnterModal && (
            <button
              className="DeleteAcc__Modal__Cancel__Btn"
              onClick={() => {
                setOpenDeleteAccModal(false);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteAccModal;
