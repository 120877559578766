import axios from "axios";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

export const getBannersList = async ({ filter, type }) => {
  try {
    const result = await axios.get(
      `${url}/banner/manage/list?filter=${filter}&type=${type}`
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getBannerImage = async () => {
  try {
    const result = await axios.get(`${url}/banner/manage/get?id=1`);
    return result;
  } catch (err) {
    return err;
  }
};

export const getCategoriesListing = async ({ filter }) => {
  try {
    const result = await axios.get(
      `${url}/categories/manage/list?filter=${filter}&getAll=true`
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getBrandsListing
 * @param
 * @description This function is used to get brands listing for homepage
 */

export const getBrandsListing = async ({
  filter,
  type,
  getAll,
  isOnDashboard,
}) => {
  try {
    const result = await axios.get(
      `${url}/brands/manage/list?filter=${filter}&type=${type}&getAll=${getAll}&isOnDashboard=${isOnDashboard}`,
      {
        order: 1,
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getCategoriesListingArray
 * @param filter, getAll
 * @description This function is used to get categories list for the homepage
 */

export const getCategoriesListingArray = async ({ filter, getAll, order }) => {
  try {
    const result = await axios.get(
      `${url}/categories/manage/userSidelist?filter=${filter}&getAll=${getAll}`,
      {
        order,
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function getBrandListingArray
 * @param filter, getAll
 * @description This function is used to get brands list for the homepage
 */

export const getBrandListingArray = async ({ filter, getAll, type }) => {
  try {
    const result = await axios.get(
      `${url}/brands/manage/list?filter=${filter}&getAll=${getAll}&type=${type}`
    );
    return result;
  } catch (err) {
    return err;
  }
};
