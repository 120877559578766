import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import { getToken } from './AuthService';

// handling private routes

const PrivateRoute = ({ children }) => {
    const tokens = getToken();
    return tokens ? <Outlet>{children}</Outlet> : <Navigate to='/login' />
};


export default PrivateRoute;