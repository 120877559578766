import axios from "axios";
import { getToken } from "../utils/AuthService";
import { isLoggedIn } from "../redux/authSlice";
import { useSelector } from "react-redux";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

export const getProductList = async ({
  categoryId = "",
  brandId = "",
  subCategoryName = "",
  subCategoryTypeSlug = "",
  search = "",
  page,
  isOnFlashSale = "",
  isOnRedemption = "",
  isNewArrival = "",
  isTrending = "",
  isBestSelling = "",
  isSuperSaver = "",
  sort = "",
  minPrice = "",
  maxPrice = "",
}) => {
  const result = await axios.patch(
    `${url}/product/listWithSlug?filter=Active${
      minPrice ? "&minPrice=" + minPrice : "&minPrice=" + 0
    }${maxPrice ? "&maxPrice=" + maxPrice : "&maxPrice=" + 10000}${
      isOnFlashSale && "&isOnFlashSale=" + isOnFlashSale
    }${categoryId && "&categorySlug=" + categoryId}${
      brandId && "&brandSlug=" + brandId
    }${subCategoryName && "&subCategorySlug=" + subCategoryName}${
      subCategoryTypeSlug && "&subCategoryTypeSlug=" + subCategoryTypeSlug
    }${isOnRedemption && "&isOnRedemption=" + isOnRedemption}${
      isNewArrival && "&isNewArrival=" + isNewArrival
    }${isTrending && "&isTrending=" + isTrending}${
      isBestSelling && "&isBestSelling=" + isBestSelling
    }${isSuperSaver && "&isSuperSaver=" + isSuperSaver}${
      sort && "&sort=" + sort
    }${page && "&page=" + page}${search && "&search=" + search}&limit=12`
  );

  return result;
};

export const getProductListWithFilter = async ({
  categoryIds,
  minPrice = 0,
  maxPrice,
  sort,
  isNewArrival = "",
  isTrending = "",
  isBestSelling = "",
  isSuperSaver = "",
}) => {
  try {
    const result = await axios.patch(
      `${url}/product/list?filter=Active${
        categoryIds && "&categoryId=" + categoryIds.join(",")
      }${minPrice ? "&minPrice=" + minPrice : "&minPrice=" + 0}${
        maxPrice ? "&maxPrice=" + maxPrice : "&maxPrice=" + 0
      }${sort && "&sort=" + sort}${
        isNewArrival && "&isNewArrival=" + isNewArrival
      }${isTrending && "&isTrending=" + isTrending}${
        isBestSelling && "&isBestSelling=" + isBestSelling
      }${isSuperSaver && "&isSuperSaver=" + isSuperSaver}`
    );
    return result;
  } catch (err) {
    return Promise.reject(err?.response);
  }
};

export const getSingleProduct = async ({ productId }) => {
  try {
    const token = getToken();
    let result;
    if (token) {
      const { tokenAccess } = token;

      result = await axios.get(`${url}/product/get?slug=${productId}`, {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          // foodauthtoken: `${foodAccessToken}`,
        },
      });
    } else {
      result = await axios.get(`${url}/product/get?slug=${productId}`);
    }
    return result;
  } catch (err) {
    console.log(err);
  }
};

export const getProductReview = async ({ productId }) => {
  try {
    const result = await axios.get(
      `${url}/product/reviews?productId=${productId}`
    );
    return result;
  } catch (err) {
    return Promise.reject(err?.response);
  }
};

export default getProductList;
