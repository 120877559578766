import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { BsArrowLeftCircle } from "react-icons/bs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Row, Col } from "react-bootstrap";
import {
  getUserProfile,
  updateIndividualEInvoiceInfo,
  updateCompanyEInvoiceInfo
} from "../../service/user.service";
//images

// Import css files
import "../../assets/scss/base/pages/account/Account.scss";
import "../../assets/scss/base/pages/global.scss";
import "../../assets/scss/base/pages/account/EditProfileForm.scss";
import "react-tabs/style/react-tabs.css";

import AccountMenu from "./AccountMenuNavigation/AccountMenu";
import Loader from "../../components/micro/loader/Loader";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";

// Images
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import { setUserDetail } from "../../redux/userSlice";

const EInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const [user, setUser] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editUserClicked, setEditUserClicked] = useState(false);
  const [editedSuccessfully, setEditedSuccessfully] = useState();
  const [notValidated, setNotValidated] = useState(false);

  const [formData, setFormData] = useState({
    username: null,
    mobileNo: null,
    email: null,
    gender: null,
    dob: null,
    file: null,
  });

  const { register, reset, handleSubmit, formState: { errors } } = useForm({
    mode: "all"
  })

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const response = await getUserProfile();

      if (response?.data?.success) {
        setUser(response?.data?.data);
        dispatch(setUserDetail(response?.data?.data));
        let defaultValues = {};
        defaultValues.name = response?.data?.data?.UserCompnayInfo?.name
        defaultValues.regNo = response?.data?.data?.UserCompnayInfo?.regNo
        defaultValues.tin = response?.data?.data?.UserCompnayInfo?.tin
        defaultValues.msicCode = response?.data?.data?.UserCompnayInfo?.msicCode
        defaultValues.sstNo = response?.data?.data?.UserCompnayInfo?.sstNo
        defaultValues.address = response?.data?.data?.UserCompnayInfo?.address
        defaultValues.contactNo = response?.data?.data?.UserCompnayInfo?.contactNo
        defaultValues.email = response?.data?.data?.UserCompnayInfo?.email
        defaultValues.businessDescription = response?.data?.data?.UserCompnayInfo?.businessDescription
        defaultValues.IcFullName = response?.data?.data?.IcFullName
        defaultValues.IcNumber = response?.data?.data?.IcNumber
        reset({ ...defaultValues })
      } else {
        console.log(response?.data?.errorMessage);
      }
      setIsLoading(false);
    };

    fetchProfile();
  }, [editedSuccessfully]);

  useEffect(() => {
    if (user) {
      let defaultValues = {};
      defaultValues.name = user?.UserCompnayInfo?.name
      defaultValues.regNo = user?.UserCompnayInfo?.regNo
      defaultValues.tin = user?.UserCompnayInfo?.tin
      defaultValues.msicCode = user?.UserCompnayInfo?.msicCode
      defaultValues.sstNo = user?.UserCompnayInfo?.sstNo
      defaultValues.address = user?.UserCompnayInfo?.address
      defaultValues.contactNo = user?.UserCompnayInfo?.contactNo
      defaultValues.email = user?.UserCompnayInfo?.email
      defaultValues.businessDescription = user?.UserCompnayInfo?.businessDescription
      defaultValues.IcFullName = user?.IcFullName
      defaultValues.IcNumber = user?.IcNumber
      reset({ ...defaultValues })
    }
  }, [isEditing]);

  const handleEditClick = (e) => {
    e.preventDefault();

    setEditUserClicked(true);
    setTimeout(() => {
      setIsEditing(true);
    }, 100);
  };

  /**
   * @function handleChangeProfileData
   * @param
   * @description This function is used to save the updates of profile
   */
  const handleChangeProfileData = async (data) => {
    setSaveLoading(true);
    let response
    if (tabIndex === 0) {
      response = await updateCompanyEInvoiceInfo({
        name: data?.name,
        regNo: data?.regNo,
        tin: data?.tin,
        msicCode: data?.msicCode,
        sstNo: data?.sstNo,
        address: data?.address,
        contactNo: data?.contactNo,
        email: data?.email,
        businessDescription: data?.businessDescription,
      });
    }
    if (tabIndex === 1) {
      response = await updateIndividualEInvoiceInfo({
        is_CompletedE_Invoice_Info: true,
        IcFullName: data?.IcFullName,
        IcNumber: data?.IcNumber,
        email: user?.email
      });
    }
    if (response?.data?.success) {
      setSaveLoading(false);
      setEditedSuccessfully(!editedSuccessfully)

      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <h4 style={{ marginTop: "0.5rem" }}>
              E-Invoice updated successfully
            </h4>
            {/* <p>This product is successfully added to cart list</p> */}
            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
      setIsEditing(false);
      navigate("/account/e-invoice");
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <h4 style={{ marginTop: "0.5rem" }}>
              {response?.data?.errorMessage}
            </h4>
            {/* <p>This product is successfully added to cart list</p> */}
            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
    setSaveLoading(false);
  };

  const renderProfile = (
    <div className="profile-details-parentAccount">
      <h3 className="accountAccount">Information form for E-Invoice</h3>
      <div className="e-invoice__block">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => {
          setTabIndex(index)
        }}>
          <TabList>
            <Tab>Company</Tab>
            <Tab>Individual</Tab>
          </TabList>
          <TabPanel>
            {isLoading ? (
              // eslint-disable-next-line react/style-prop-object
              <div style={{ margin: "auto" }}>
                <Loader />
              </div>
            ) : (
              <div className="dropdown-parentAccount-e-invoice">
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Company Name</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.name || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Company Registration No.</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.regNo || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Tax identification No. (TIN)</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.tin || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">MSIC Code</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.msicCode || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">SST No.</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.sstNo || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Correspondence Address</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.address || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Contact No.</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.contactNo ? `+6${user?.UserCompnayInfo?.contactNo}` : "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">E-Invoice receiver email address</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccountEmail">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.email || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-3">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Business activity description</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.UserCompnayInfo?.businessDescription || "-"}</span>
                    </div>
                  </div>
                </div>

                <button onClick={handleEditClick} className="buttonAccount">
                  <div className="state-layer1Account">
                    <div className="label-text1Account">{user?.UserCompnayInfo ? "Edit" : "Add Now"}</div>
                  </div>
                </button>
              </div>
            )}
          </TabPanel>
          <TabPanel>
            {isLoading ? (
              // eslint-disable-next-line react/style-prop-object
              <div style={{ margin: "auto" }}>
                <Loader />
              </div>
            ) : (
              <div className="dropdown-parentAccount-e-invoice">
                <div className="frame-divAccount mb-2">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">Full Name as in IC</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.IcFullName || "-"}</span>
                    </div>
                  </div>
                </div>
                <div className="frame-divAccount mb-3">
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">IC Number</div>
                  </div>
                  <div className="full-name-parentAccount">
                    <div className="full-nameAccount">
                      <span className="flex-shrink-0 me-2">: &nbsp;</span>
                      <span>{user?.IcNumber || "-"}</span>
                    </div>
                  </div>
                </div>

                <button onClick={handleEditClick} className="buttonAccount">
                  <div className="state-layer1Account">
                    <div className="label-text1Account">{(user?.IcFullName || user?.IcNumber) ? "Edit" : "Add Now"}</div>
                  </div>
                </button>
              </div>
            )}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );

  const renderEditProfile = (
    <div className="frameparent5EditProfile-e-invoice">
      <div className="d-flex align-items-start mb-3">
        <div>
          <BsArrowLeftCircle
            style={{
              color: "#D71513",
              // width: "4%",
              // height: "4%",
              marginRight: "1rem",
              cursor: "pointer",
            }}
            size={30}
            onClick={() => setIsEditing(false)}
          />
        </div>
        <span className="title text-dark">{tabIndex === 0 && user?.UserCompnayInfo ? "Edit" : tabIndex === 0 && !user?.UserCompnayInfo ? "Add" : ""}{tabIndex === 1 && (user?.IcFullName || user?.IcNumber) ? "Edit" : tabIndex === 1 && !user?.IcFullName && !user?.IcNumber ? "Add" : ""} {tabIndex === 0 ? "Company" : "Individual"} Information form for E-Invoice</span>
      </div>
      <form className="dropdown-groupEditProfile" onSubmit={handleSubmit(handleChangeProfileData)}>
        {tabIndex === 0 &&
          <div className="formEditProfile">
            <div className="input-fieldEditProfile">
              <div className="input-fieldEditProfile">
                <div className="label4EditProfile">Company Name*</div>
                <input
                  className="input3EditProfile"
                  type="text"
                  placeholder="Enter here"
                  {...register("name", {
                    required: "Company Name is required"
                  })}
                />
              </div>
              {errors?.name &&
                <div className="hint-text3EditProfile text-danger">
                  {errors?.name?.message}
                </div>
              }
            </div>
            <Row className="w-100 mx-0">
              <Col sm={6} className="px-xs-0 ps-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="label4EditProfile">Company Registration No.*</div>
                  <input
                    className="input3EditProfile"
                    type="text"
                    placeholder="Enter here"
                    {...register("regNo", {
                      required: "Company Registration No is required"
                    })}
                  />
                </div>
                {errors?.regNo &&
                  <div className="hint-text3EditProfile text-danger">
                    {errors?.regNo?.message}
                  </div>
                }
              </Col>
              <Col sm={6} className="px-xs-0 pe-sm-0 mt-3 mt-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="label4EditProfile">Tax identification No. (TIN)*</div>
                  <input
                    className="input3EditProfile"
                    type="text"
                    placeholder="Enter here"
                    {...register("tin", {
                      required: "Tax identification No is required"
                    })}
                  />
                </div>
                {errors?.tin &&
                  <div className="hint-text3EditProfile text-danger">
                    {errors?.tin?.message}
                  </div>
                }
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col sm={6} className="px-xs-0 ps-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="label4EditProfile">MSIC Code*</div>
                  <input
                    className="input3EditProfile"
                    type="text"
                    placeholder="Enter here"
                    {...register("msicCode", {
                      required: "MSIC Code is required"
                    })}
                  />
                </div>
                {errors?.msicCode &&
                  <div className="hint-text3EditProfile text-danger">
                    {errors?.msicCode?.message}
                  </div>
                }
              </Col>
              <Col sm={6} className="px-xs-0 pe-sm-0 mt-3 mt-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="label4EditProfile">SST No.*</div>
                  <input
                    className="input3EditProfile"
                    type="text"
                    placeholder="Enter here"
                    {...register("sstNo", {
                      required: "SST No is required"
                    })}
                  />
                </div>
                {errors?.sstNo &&
                  <div className="hint-text3EditProfile text-danger">
                    {errors?.sstNo?.message}
                  </div>
                }
              </Col>
            </Row>
            <div className="input-fieldEditProfile">
              <div className="input-fieldEditProfile">
                <div className="label4EditProfile">Correspondence Address*</div>
                <input
                  className="input3EditProfile"
                  type="text"
                  placeholder="Enter here"
                  {...register("address", {
                    required: "Address is required"
                  })}
                />
              </div>
              {errors?.address &&
                <div className="hint-text3EditProfile text-danger">
                  {errors?.address?.message}
                </div>
              }
              {/* <div className="hint-text3EditProfile">
              Must be at least 8 characters.
            </div> */}
            </div>
            <Row className="w-100 mx-0">
              <Col sm={6} className="px-xs-0 ps-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="input-fieldEditProfile">
                    <div className="label4">Contact No.*</div>
                    <div className="phoneInput__And__Button">
                      <div className="phoneDivEditProfile">
                        {/* <input value="+60" className="codeEditProfile" /> */}
                        <p className="DefaultCodeText">+6</p>
                        <input
                          ref={inputRef}
                          className="input3EditProfile  phoneEditProfile"
                          type="number"
                          placeholder="Enter here"
                          {...register("contactNo", {
                            required: "Contact No is required",
                            minLength: {
                              value: 9,
                              message: 'Contact No must be at least 9 digits'
                            },
                            maxLength: {
                              value: 11,
                              message: 'Contact No cannot exceed 11 digits'
                            }
                          })}
                        />
                      </div>
                      {errors?.contactNo &&
                        <div className="hint-text3EditProfile text-danger">
                          {errors?.contactNo?.message}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6} className="px-xs-0 pe-sm-0 mt-3 mt-sm-0">
                <div className="input-fieldEditProfile">
                  <div className="input-fieldEditProfile">
                    <div className="label4EditProfile">Email Address*</div>
                    <input
                      className="input3EditProfile"
                      type="text"
                      placeholder="Enter here"
                      {...register("email", {
                        required: "Email Address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Enter a valid email"
                        }
                      })}
                    />
                  </div>
                  {errors?.email &&
                    <div className="hint-text3EditProfile text-danger">
                      {errors?.email?.message}
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <div className="input-fieldEditProfile">
              <div className="input-fieldEditProfile">
                <div className="label4EditProfile">Business activity description</div>
                <textarea
                  className="input3EditProfile"
                  type="text"
                  rows={3}
                  placeholder="Enter here"
                  {...register("businessDescription")}
                />
              </div>
            </div>
          </div>
        }
        {tabIndex === 1 &&
          <div className="formEditProfile">
            <div className="input-fieldEditProfile">
              <div className="input-fieldEditProfile">
                <div className="label4EditProfile">Full Name as in IC*</div>
                <input
                  className="input3EditProfile"
                  type="text"
                  placeholder="Enter here"
                  {...register("IcFullName", {
                    required: "IC Full Name is required"
                  })}
                />
              </div>
              {errors?.IcFullName &&
                <div className="hint-text3EditProfile text-danger">
                  {errors?.IcFullName?.message}
                </div>
              }
            </div>
            <div className="input-fieldEditProfile">
              <div className="input-fieldEditProfile">
                <div className="label4EditProfile">IC Number*</div>
                <input
                  className="input3EditProfile"
                  type="text"
                  placeholder="Enter here"
                  {...register("IcNumber", {
                    required: "IC Number is required"
                  })}
                />
              </div>
              {errors?.IcNumber &&
                <div className="hint-text3EditProfile text-danger">
                  {errors?.IcNumber?.message}
                </div>
              }
            </div>
          </div>
        }
        <button
          type="submit"
          className="button1EditProfile"
        >
          <div className="state-layer2EditProfile">
            <div className="label-text2EditProfile">
              {isSaveLoading ? "Loading..." : "Save Details"}
            </div>
          </div>
        </button>
      </form>
    </div>
  );

  return (
    <>
      <AccountMenu>{isEditing ? renderEditProfile : renderProfile}</AccountMenu>
    </>
  );
};

export default EInvoice;
