/* eslint-disable no-undef */
import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../../assets/scss/base/pages/authentication/SignUp.scss";
import "../../assets/scss/base/pages/index.scss";

// API service
import { setUserSession } from "../../utils/AuthService";
import { signUpUser } from "../../service/auth.service";
// Redux-Service
import { setTemporaryPhoneNumber } from "../../redux/utilitySlice";

// images
import { logo } from "../../assets/images/authentication";
import xClose from "../../assets/images/homepage/xClose.svg";
import XClose from "../../assets/images/homepage/x-close.svg";

import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import {
  setLoginModalToggle,
  setRegisterModalToggle,
} from "../../redux/modalsSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notValidated, setNotValidated] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [passwordOneLowerUpperCase, setPasswordOneLowerUpperCase] =
    useState(false);
  const [passwordOneNumber, setPasswordOneNumber] = useState(false);
  const [passwordOneSpecialCharacter, setPasswordOneSpecialCharacter] =
    useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);
  const [confirmPasswordFieldClicked, setConfirmPasswordFieldClicked] =
    useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePasswordTwo, setTogglePasswordTwo] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState("");

  // Length-Error
  const [phoneLengthError, setPhoneLengthError] = useState(false);

  // Password-validation
  let timeoutId;
  const passwordNumberRegex = /^(?=.*\d)/;
  const passwordSpecialRegex = /^(?=.*\W)/;
  const passwordLengthRegex = /^.{8,}$/;
  const passwordLowerUpperRegex = /^(?=.*[a-z])(?=.*[A-Z])/;

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleName = (e) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleEmail = (e) => {
    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setNotValidated(true);
      } else {
        setNotValidated(false);
      }
    } else {
      setNotValidated(false);
    }

    setFormData({
      ...formData,
      email: e.target.value,
    });
  };

  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;
    setPhoneLengthError(false);

    if (inputValue.length >= 9 && inputValue.length <= 11) {
      setFormData({
        ...formData,
        phoneNumber: e.target.value,
      });
      setPhoneInputError("");
    } else {
      setFormData({
        ...formData,
        phoneNumber: e.target.value,
      });
      setPhoneInputError("Phone number must be between 9 and 11 digits.");
    }
    if (inputValue.length < 1) {
      setPhoneInputError("");
    }
  };

  // const handlePasswordFieldClicked = () => {
  //   setPasswordFieldClicked(true);
  // };

  const handlePassword = (e) => {
    const newPassword = e.target.value;
    if (e.target.value) {
      setPasswordFieldClicked(true);
    } else {
      setPasswordFieldClicked(false);
    }
    if (newPassword === formData.password_confirmation) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
    setFormData({
      ...formData,
      password: newPassword,
    });
    if (e.target.value) {
      setPasswordFieldClicked(true);
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setPasswordOneLowerUpperCase(passwordLowerUpperRegex.test(newPassword));
      setPasswordOneSpecialCharacter(passwordSpecialRegex.test(newPassword));
      setPasswordOneNumber(passwordNumberRegex.test(newPassword));
      setPasswordLength(passwordLengthRegex.test(newPassword));
    }, 300);
  };

  // const handleConfirmPasswordFieldClicked = () => {
  //   setConfirmPasswordFieldClicked(true)
  // }

  const handleConfirmPassword = (e) => {
    if (formData.password === e.target.value) {
      setFormData({
        ...formData,
        password_confirmation: e.target.value,
      });
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
    if (e?.target?.value?.length > 0) {
      setConfirmPasswordFieldClicked(true);
    } else {
      setConfirmPasswordFieldClicked(false);
    }
  };

  const handleShowPasswordOne = () => {
    setTogglePassword(!togglePassword);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (formData.phoneNumber?.length < 1) {
      setPhoneLengthError(true);
      return false;
    }
    if (!passwordMatch || phoneInputError) return false;
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LelLbYqAAAAAP4I1VJDijFQg-Z9pnQznqPSgmDf", {
          action: "submit",
        })
        .then(async function (token) {
          // Add your logic to submit to your backend server here.
          const result = await signUpUser({
            username: formData.name,
            mobileNo: formData.phoneNumber,
            email: formData.email,
            password: formData.password,
            recaptchaResponse: token,
          });

          if (result?.data?.success) {
            dispatch(setTemporaryPhoneNumber(formData.phoneNumber));
            const token = result?.data?.data?.token;
            const user = result?.data?.data?.userId;
            const tokens = {
              tokenAccess: token,
            };
            setUserSession(tokens, user);
            dispatch(setRegisterModalToggle(false));
            navigate("/verification", {
              state: {
                type: "Account Verification",
                btnText: "Verify",
                phoneNumber: formData.phoneNumber,
              },
            });
          } else {
            toast(
              (t) => (
                <div className="toast__block">
                  <div className="toast__block__icon">
                    <img src={AlertCircle} alt="" />
                  </div>
                  <div className="toast__block__content">
                    <h4>{result?.data?.errorMessage}</h4>
                    <p>Please, provide correct details</p>
                  </div>

                  <button onClick={() => toast.dismiss(t.id)}>
                    <img src={XClose} alt="" />
                  </button>
                </div>
              ),
              {
                style: {
                  borderRadius: "10px",
                  background: "#D92D20",
                  color: "#FFD7D3",
                },
              }
            );
          }
        });
    });
  };

  const handleTogglePasswordTwo = () => {
    setTogglePasswordTwo(!togglePasswordTwo);
  };

  const handleSignInClick = () => {
    dispatch(setRegisterModalToggle(false));
    dispatch(setLoginModalToggle(true));
  };

  return (
    <>
      <div className="sign-up">
        <div className="content7">
          <img
            src={xClose}
            alt="cross-Tcon"
            className="cross___icon"
            onClick={() => navigate("/")}

          // onClick={() => dispatch(setRegisterModalToggle(false))}
          />
          <div className="header3">
            <img
              className="logo-1-icon"
              onClick={() => navigate("/")}
              alt="logo"
              src={logo}
            />
            <div className="text18">Register</div>
          </div>
          <form className="content8" onSubmit={handleSubmitForm}>
            <div className="form2">
              <div className="input-field3">
                <div className="input-field3">
                  <div className="label4">Name*</div>
                  <input
                    className="input9"
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleName(e)}
                    placeholder="Enter your name"
                  />
                </div>
              </div>
              <div className="input-field3">
                <div className="input-field3">
                  <div className="label4">Phone Number*</div>
                  <div className="phone-div">
                    <input className="prefix-phone" value="+6" />
                    <input
                      className="input9 phone"
                      type="number"
                      value={formData.phoneNumber}
                      onChange={(e) => handlePhoneNumber(e)}
                      placeholder={"Enter your phone number"}
                    />
                  </div>
                  {phoneInputError && (
                    <span className="confirm-password-check-red">
                      {phoneInputError}
                    </span>
                  )}
                  {phoneLengthError && (
                    <span className="confirm-password-check-red">
                      Phone number must not empty
                    </span>
                  )}
                </div>
              </div>
              <div className="input-field3">
                <div className="input-field3">
                  <div className="label4">Email*</div>
                  <input
                    className="input9"
                    type="text"
                    value={formData.email}
                    onChange={(e) => handleEmail(e)}
                    placeholder="Enter your email"
                  />
                  {notValidated ? (
                    <span className="hint-text4-email">
                      Enter valid email address
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="input-field3">
                <div className="input-field3">
                  <div className="label4">Password*</div>
                  <div className="pass-div">
                    <input
                      className="input9"
                      type={togglePassword ? "text" : "password"}
                      value={formData.password}
                      // onClick={handlePasswordFieldClicked}
                      // onInput={validatePasswordConditions}
                      onChange={(e) => handlePassword(e)}
                      placeholder="Create a password"
                    />

                    {togglePassword ? (
                      <LuEyeOff
                        className="eye-icon-1"
                        onClick={handleShowPasswordOne}
                      />
                    ) : (
                      <LuEye
                        className="eye-icon-1"
                        onClick={handleShowPasswordOne}
                      />
                    )}
                  </div>

                  {passwordFieldClicked && (
                    <div className="hint-text4">
                      Your password must contain:
                      <br />
                      {passwordOneLowerUpperCase ? (
                        <p className="password-lower-upper-green">
                          {" "}
                          ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                        </p>
                      ) : (
                        <p className="password-lower-upper-red">
                          {" "}
                          ✔ {`a lowercase and uppercase letter (a-z; A-Z)`}{" "}
                        </p>
                      )}
                      {passwordOneNumber ? (
                        <p className="password-number-green"> ✔ a number </p>
                      ) : (
                        <p className="password-number-red"> ✔ a number </p>
                      )}
                      {passwordOneSpecialCharacter ? (
                        <p className="password-special-character-green">
                          {" "}
                          ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                        </p>
                      ) : (
                        <p className="password-special-character-red">
                          {" "}
                          ✔ {`a special character ($ & ! @ ^ *)`}{" "}
                        </p>
                      )}
                      {passwordLength ? (
                        <p className="password-length-green">
                          {" "}
                          ✔ at least 8 characters{" "}
                        </p>
                      ) : (
                        <p className="password-length-red">
                          {" "}
                          ✔ at least 8 characters{" "}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="input-field3">
                <div className="input-field3">
                  <div className="label4">Confirm Password*</div>
                  <div className="pass-div">
                    <input
                      className="input9"
                      type={togglePasswordTwo ? "text" : "password"}
                      // onClick={handleConfirmPasswordFieldClicked}
                      onChange={(e) => handleConfirmPassword(e)}
                      placeholder="Confirm Created password"
                    />
                    {togglePasswordTwo ? (
                      <LuEyeOff
                        className="eye-icon-1"
                        onClick={handleTogglePasswordTwo}
                      />
                    ) : (
                      <LuEye
                        className="eye-icon-1"
                        onClick={handleTogglePasswordTwo}
                      />
                    )}
                  </div>
                  {confirmPasswordFieldClicked && (
                    <span
                      className={
                        passwordMatch
                          ? "confirm-password-check-green"
                          : "confirm-password-check-red"
                      }
                    >
                      Password is {!passwordMatch && "not"} same
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="actions3">
              <button type="submit" className="buttons7">
                <div className="text19">Register</div>
              </button>
            </div>
          </form>
          <div className="row2">
            <div className="text20">Already have an account?</div>
            <button onClick={handleSignInClick} className="buttons8">
              <div className="text21">Sign In</div>
            </button>
          </div>
        </div>
        <Toaster position="top-right" />
      </div>
    </>
  );
};

export default SignUp;
