import React from "react";
import { useNavigate } from "react-router-dom";
import Cart_Image from "../../assets/images/account/Order/Cart_Image.svg";

//styles
import "../../assets/scss/base/pages/account/Order/NoOrder.scss";

const NoOrder = () => {
  const navigate = useNavigate();
  return (
    <div className="NoOrder__Card">
      <img src={Cart_Image} alt="cart-banner" />
      <h3>You Haven't Ordered any items yet!</h3>
      <p className="NoOrder__CardPara">Order your favorite item</p>
      <button className="NoOrder__CardButton" onClick={() => navigate("/")}>
        Home
      </button>
    </div>
  );
};

export default NoOrder;
