import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/scss/base/pages/Notification/Notification.scss";
import {
  selectNotifications,
  selectTotalItem,
  setNotificationList,
  setPages,
  setTotalNotification,
} from "../../redux/notificationSlice";
import moment from "moment";
import Pcoin from "../../assets/P-Coin.png";
import EmptyNotifyEmoji from "../../assets/images/Notification/EmptyNotifyEmoji.svg";
import { getNotificationService } from "../../service/notification.service";
const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const Notification = ({
  setNotificationDropdownOpen,
  setNotificationLimit,
  loading,
}) => {
  const navigate = useNavigate();
  const userNotification = useSelector(selectNotifications);
  const totalItems = useSelector(selectTotalItem);
  const dispatch = useDispatch();
  const [itemLoaded, setItemLoaded] = useState(20);
  // let itemLoaded = 10;
  // function getLimit(e, limit) {
  //   e.stopPropagation();
  //   itemLoaded = itemLoaded + limit;
  //   setNotificationLimit(itemLoaded);
  // }

  function getLimit(e) {
    e.stopPropagation();
    setItemLoaded(itemLoaded + 10);
    setNotificationLimit(itemLoaded);
  }
  return (
    <div
      id="Notification__Sheet"
      className="Notification__Main__Container"
      onClick={() => setNotificationDropdownOpen(true)}
    >
      {userNotification?.length > 0 ? (
        userNotification?.map((item, index) => {
          return (
            <div className="Notification__Container" key={index}>
              <h6
                className={
                  item?.title === "Delivered"
                    ? "Notification__Heading"
                    : "Notification__Heading__In__Red"
                }
              >
                {JSON.parse(item.data)?.OrderItems
                  ? JSON.parse(item.data)?.OrderItems?.map((currentItem) => {
                    return (
                      <div
                        style={{
                          marginBottom: "1rem ",
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            `/account/order/${JSON.parse(item?.data)?.id}`
                          )
                        }
                      >
                        <img
                          src={`${imageCDN}/${currentItem?.Product?.thumbnail}`}
                          alt=""
                          style={{ width: "40px" }}
                        />{" "}
                        <div>
                          <span className="Notification__Heading">
                            {item?.title}{" "}
                          </span>{" "}
                          <span
                            style={{
                              color: "Black",
                              fontWeight: "400",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                            }}
                          >
                            : {currentItem?.Product?.name.slice(0, 39)}...
                          </span>
                        </div>
                      </div>
                    );
                  })
                  : item?.title + " :"}
              </h6>
              <p className="Notification__Content">
                {JSON.parse(item.data)?.dealingQuantity &&
                  item?.title !== "Admin Transection" ? (
                  <p>
                    You have earned{" "}
                    <span style={{ color: "#D71513", fontWeight: "600" }}>
                      <img src={Pcoin} alt="icon" style={{ width: "15px" }} />
                      {JSON.parse(item.data)?.dealingQuantity} P-Coins{" "}
                    </span>
                    from {JSON.parse(item.data)?.referenceType}
                  </p>
                ) : item?.title === "Admin Transection" ? (
                  <div style={{ alignItems: "start", gap: "2px" }}>
                    <span
                      style={{
                        color: "#D71513",
                        fontWeight: "600",

                        display: "inline",
                      }}
                    >
                      <img
                        src={Pcoin}
                        alt="icon"
                        style={{ width: "15px", display: "inline" }}
                      />{" "}
                      {JSON.parse(item.data)?.dealingQuantity} P-Coins :{" "}
                    </span>{" "}
                    <span> {JSON.parse(item.data)?.referenceType}</span>
                  </div>
                ) : !JSON.parse(item.data)?.OrderItems ? (
                  item.content
                ) : null}
                {/* {
                  item?.title ==="Admin Transection" &&
                  <div >
                    <span style={{ color: "#D71513", fontWeight: "600" }}>
                      <img src={Pcoin} alt="icon" style={{ width: "15px" }} />
                      {JSON.parse(item.data)?.dealingQuantity} P-Coins{" "}
                    </span> :
                    <p>
                      {JSON.parse(item.data)?.referenceType
}
                    </p>
                  </div>
                } */}
              </p>
              <p className="Notification__Date">
                {moment(item?.createdAt).format("Do MMM YYYY, hh:mm A")}
              </p>
            </div>
          );
        })
      ) : (
        <div className="Empty__Notification__Container">
          <img
            src={EmptyNotifyEmoji}
            alt="emoji"
            className="NotifyBell__Emoji"
          />
          <p className="Empty__Notification__Text">
            You dont have any notification at the moment!
          </p>
        </div>
      )}

      {totalItems !== userNotification?.length && (
        <div style={{ textAlign: "center" }}>
          <button
            onClick={(e) => {
              getLimit(e);
            }}
            className="Notification__Btn"
          >
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Notification;
