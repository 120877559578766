import React, { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { Button } from "reactstrap";
import { BiCategory } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";

// Redux-Settings
import {
  selectIsSmallScreen,
  setCategoryDropdownValue,
} from "../../../redux/utilitySlice";
import { selectCategoriesList } from "../../../redux/categoriesSlice";

// Images
import ProductPlaceHolder from "../../../assets/images/placeholder/ProductPlaceHolder.svg";

// Styles
import "../../../assets/scss/base/pages/homepage/MainCategoriesDropdown.scss";
import SubCategoryDropdown from "./SubCategoryDropdown";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const MainCategoriesDropdown = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const categoriesArray = useSelector(selectCategoriesList);
  const [toogle, setToogle] = useState(false);
  const [openSideDropdown, setOpenSideDropdown] = useState(false);
  const colorPalette = [
    "#FCE9D8",
    "#DEE2EB",
    "#F8D2D1",
    "#E0D4E2",
    "#C9F8F5",
    "#D9DAD4",
    "#8DE5E7",
    "#D9DAD4",
    "#F6E3B9",
  ];
  function handleDropDownToggle() {
    document.addEventListener("click", (e) => {
      e.stopPropagation();
      if (e.target.id === "DropDown__Btn") {
        setToogle(!toogle);
        e.stopPropagation();
      } else if (e.target.id === "MenuAccordian") {
        setToogle(true);
      } else {
        setToogle(false);
      }
    });
  }
  return (
    <div className="catagories__container">
      <Button type="button" className="catagories__container_btn">
        <div
          id="DropDown__Btn"
          className="text9Cat"
          onClick={() => handleDropDownToggle()}
        >
          <BiCategory
            className="category-childCat"
            alt="icon"
            id="DropDown__Btn"
            onClick={() => handleDropDownToggle()}
            style={{ marginRight: "0.2rem" }}
          />
          {!isSmallScreen && "Categories"}
        </div>
      </Button>
      {isSmallScreen
        ? toogle && (
            <div className="catagories__container__inner">
              <Accordion>
                {categoriesArray &&
                  categoriesArray?.map((category, index) => {
                    const bgColor = colorPalette[index % colorPalette.length];
                    return (
                      <AccordionItem key={category?.id}>
                        {({ open }) => (
                          <>
                            <AccordionHeader
                              className={`accordion-header-main ${
                                open ? "accordion-header-main-active" : ""
                              }`}
                            >
                              <div
                                style={{
                                  backgroundColor: bgColor,
                                }}
                                id="MenuAccordian"
                                className="accordion-header-inner"
                                onClick={(e) => handleDropDownToggle(e)}

                                // onClick={(e) => {
                                //   e.stopPropagation();

                                //   navigate(`/catalogue/${category?.slug}`);
                                // }}
                              >
                                <div className="accordion-title-block">
                                  {category?.file ? (
                                    <div className="cat-list-icon-block">
                                      <img
                                        className="cat-list-icon"
                                        src={`${imageCDN}/${category?.file}`}
                                        alt="icon"
                                      />
                                    </div>
                                  ) : (
                                    <div className="cat-list-icon-block">
                                      <img
                                        className="cat-list-icon"
                                        src={ProductPlaceHolder}
                                        alt="icon"
                                      />
                                    </div>
                                  )}

                                  <h3
                                    id="MenuAccordian"
                                    onClick={(e) => handleDropDownToggle(e)}
                                  >
                                    {category?.name}
                                  </h3>
                                </div>
                                <FaChevronRight height="15px" width="15px" />
                              </div>
                            </AccordionHeader>

                            <AccordionBody>
                              <div className="accordion-body">
                                {category?.CategoryBrands?.length > 0 &&
                                  category?.CategoryBrands?.map((item) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                        padding: "0 15px",
                                        cursor: "pointer",
                                      }}
                                      key={item?.id}
                                      onClick={() => {
                                        dispatch(
                                          setCategoryDropdownValue({
                                            state: {
                                              id: item?.categoryId,
                                              brandId: item?.Brand?.id,
                                              categoryName: category?.name,
                                              subCatagoryName: "",
                                              brandName: item?.Brand?.name,
                                              SubCategory:
                                                category?.SubCategory,
                                            },
                                          })
                                        );
                                        navigate(
                                          `/catalogue/${category.slug}/${item.slug}`,
                                          {
                                            state: {
                                              id: item?.categoryId,
                                              brandId: item?.Brand?.id,
                                              categoryName: category?.name,
                                              subCatagoryName: "",
                                              brandName: item?.Brand?.name,
                                              SubCategory:
                                                category?.SubCategory,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      {/* {item?.Brand?.file ? (
                                        <img
                                          style={{ width: "25px" }}
                                          // src={`${url}/${item?.Brand?.file}`}
                                          alt="icon"
                                        />
                                      ) : (
                                        <img
                                          style={{ width: "25px" }}
                                          src={ProductPlaceHolder}
                                          alt="icon"
                                        />
                                      )} */}
                                      <p>{item?.Brand?.name}</p>
                                    </div>
                                  ))}

                                {category?.SubCategory?.length > 0 &&
                                  category?.SubCategory?.filter(
                                    (item) => item.status === "Active"
                                  )
                                    .sort((a, b) => {
                                      if (a.order === null) {
                                        return 1;
                                      }
                                      if (b.order === null) {
                                        return -1;
                                      }
                                      return a.order - b.order;
                                    })
                                    .map((subCatagoryItem) => (
                                      <AccordionItem key={subCatagoryItem?.id}>
                                        <>
                                          {(subCatagoryItem?.CategoryBrands
                                            ?.length > 0 ||
                                            subCatagoryItem?.SubCategoryType
                                              ?.length > 0) && (
                                            <>
                                              <AccordionHeader
                                                className={`subCatagory-accordion-header-main`}
                                              >
                                                <div
                                                  className="subCatagory-accordion-header-inner"
                                                  id="MenuAccordian"
                                                  onClick={(e) =>
                                                    handleDropDownToggle(e)
                                                  }
                                                >
                                                  <div className="subCatagory-accordion-title-block">
                                                    {subCatagoryItem?.icon ? (
                                                      <img
                                                        style={{
                                                          width: "25px",
                                                        }}
                                                        src={`${imageCDN}/${subCatagoryItem?.icon}`}
                                                        alt="icon"
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{
                                                          width: "25px",
                                                        }}
                                                        src={ProductPlaceHolder}
                                                        alt="icon"
                                                      />
                                                    )}
                                                    <h3
                                                      onClick={(e) =>
                                                        navigate(
                                                          `/catalogue/${category.slug}/${subCatagoryItem.slug}`,
                                                          {
                                                            state: {
                                                              id: category?.categoryId,
                                                              brandId:
                                                                category?.Brand
                                                                  ?.id,
                                                              categoryName:
                                                                category?.name,
                                                              subCatagoryName:
                                                                "",
                                                              brandName:
                                                                category?.Brand
                                                                  ?.name,
                                                              SubCategory:
                                                                category?.SubCategory,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {subCatagoryItem.name}
                                                    </h3>
                                                  </div>
                                                  <FaChevronRight
                                                    height="15px"
                                                    width="15px"
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                  />
                                                </div>
                                              </AccordionHeader>
                                              <AccordionBody>
                                                
                                              {subCatagoryItem?.CategoryBrands
                                                  ?.length > 0 &&
                                                  subCatagoryItem?.CategoryBrands?.map(
                                                    (subCatagoryBrandsitem) => (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "5px",
                                                          alignItems: "center",
                                                          padding: "0 15px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          dispatch(
                                                            setCategoryDropdownValue(
                                                              {
                                                                state: {
                                                                  id: subCatagoryItem?.parentId,
                                                                  subCatId:
                                                                    subCatagoryBrandsitem?.categoryId,
                                                                  brandId:
                                                                    subCatagoryBrandsitem
                                                                      ?.Brand
                                                                      ?.id,
                                                                  categoryName:
                                                                    category?.name,
                                                                  subCatagoryName:
                                                                    subCatagoryItem?.name,
                                                                  brandName:
                                                                    subCatagoryBrandsitem
                                                                      ?.Brand
                                                                      ?.name,
                                                                  SubCategory:
                                                                    category?.SubCategory,
                                                                },
                                                              }
                                                            )
                                                          );
                                                          navigate(
                                                            `/catalogue/${category.slug}/${subCatagoryItem?.slug}/${subCatagoryBrandsitem?.Brand?.slug}`,
                                                            {
                                                              state: {
                                                                id: subCatagoryItem?.parentId,
                                                                subCatId:
                                                                  subCatagoryBrandsitem?.categoryId,
                                                                brandId:
                                                                  subCatagoryBrandsitem
                                                                    ?.Brand?.id,
                                                                categoryName:
                                                                  category?.name,
                                                                subCatagoryName:
                                                                  subCatagoryItem?.name,
                                                                brandName:
                                                                  subCatagoryBrandsitem
                                                                    ?.Brand
                                                                    ?.name,
                                                                SubCategory:
                                                                  category?.SubCategory,
                                                              },
                                                            }
                                                          );
                                                        }}
                                                        key={
                                                          subCatagoryBrandsitem?.id
                                                        }
                                                      >
                                                        {/* {subCatagoryBrandsitem
                                                        ?.Brand?.file ? (
                                                        <img
                                                          style={{
                                                            width: "25px",
                                                          }}
                                                          src={`${url}/${subCatagoryBrandsitem?.Brand?.file}`}
                                                          alt="icon"
                                                        />
                                                      ) : (
                                                        <img
                                                          style={{
                                                            width: "25px",
                                                          }}
                                                          src={
                                                            ProductPlaceHolder
                                                          }
                                                          alt="icon"
                                                        />
                                                      )} */}

                                                        <p>
                                                          {
                                                            subCatagoryBrandsitem
                                                              ?.Brand?.name
                                                          }
                                                        </p>
                                                      </div>
                                                    )
                                                  )}

                                                {subCatagoryItem
                                                  ?.SubCategoryType?.length >
                                                  0 &&
                                                  subCatagoryItem?.SubCategoryType?.map(
                                                    (subCategoryTypeItem) => (
                                                      <AccordionItem
                                                        key={
                                                          subCategoryTypeItem?.id
                                                        }
                                                      >
                                                        {subCategoryTypeItem
                                                          ?.CategoryBrands
                                                          ?.length > 0 && (
                                                          <>
                                                            <AccordionHeader
                                                              className={`subCatagory-accordion-header-main`}
                                                            >
                                                              <div className="subCatagory-accordion-header-inner">
                                                                <div className="subCatagory-accordion-title-block">
                                                                  {/* {subCategoryTypeItem?.icon ? (
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                    src={`${url}/${subCategoryTypeItem?.icon}`}
                                                                    alt="icon"
                                                                  />
                                                                ) : (
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                    src={
                                                                      ProductPlaceHolder
                                                                    }
                                                                    alt="icon"
                                                                  />
                                                                )} */}
                                                                  <h3>
                                                                    {
                                                                      subCategoryTypeItem.name
                                                                    }
                                                                  </h3>
                                                                </div>
                                                                <FaChevronRight
                                                                  height="15px"
                                                                  width="15px"
                                                                />
                                                              </div>
                                                            </AccordionHeader>
                                                            <AccordionBody>
                                                              {subCategoryTypeItem
                                                                ?.CategoryBrands
                                                                ?.length > 0 &&
                                                                subCategoryTypeItem?.CategoryBrands?.map(
                                                                  (
                                                                    subCatagoryTypeBrandsitem
                                                                  ) => (
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        gap: "5px",
                                                                        alignItems:
                                                                          "center",
                                                                        padding:
                                                                          "0 15px",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        dispatch(
                                                                          setCategoryDropdownValue(
                                                                            {
                                                                              state:
                                                                                {
                                                                                  id: subCategoryTypeItem?.parentId,
                                                                                  subCategoryTypeId:
                                                                                    subCatagoryTypeBrandsitem?.categoryId,
                                                                                  brandId:
                                                                                    subCatagoryTypeBrandsitem
                                                                                      ?.Brand
                                                                                      ?.id,
                                                                                  categoryName:
                                                                                    category?.name,
                                                                                  subCatagoryName:
                                                                                    subCategoryTypeItem?.name,
                                                                                  brandName:
                                                                                    subCatagoryTypeBrandsitem
                                                                                      ?.Brand
                                                                                      ?.name,
                                                                                  SubCategory:
                                                                                    category?.SubCategory,
                                                                                },
                                                                            }
                                                                          )
                                                                        );
                                                                        navigate(
                                                                          "/catalogue",
                                                                          {
                                                                            state:
                                                                              {
                                                                                id: subCategoryTypeItem?.parentId,
                                                                                subCategoryTypeId:
                                                                                  subCatagoryTypeBrandsitem?.categoryId,
                                                                                brandId:
                                                                                  subCatagoryTypeBrandsitem
                                                                                    ?.Brand
                                                                                    ?.id,
                                                                                categoryName:
                                                                                  category?.name,
                                                                                subCatagoryName:
                                                                                  subCategoryTypeItem?.name,
                                                                                brandName:
                                                                                  subCatagoryTypeBrandsitem
                                                                                    ?.Brand
                                                                                    ?.name,
                                                                                SubCategory:
                                                                                  category?.SubCategory,
                                                                              },
                                                                          }
                                                                        );
                                                                      }}
                                                                      key={
                                                                        subCatagoryTypeBrandsitem?.id
                                                                      }
                                                                    >
                                                                      {/* {subCatagoryTypeBrandsitem
                                                                      ?.Brand
                                                                      ?.file ? (
                                                                      <img
                                                                        style={{
                                                                          width:
                                                                            "25px",
                                                                        }}
                                                                        src={`${url}/${subCatagoryTypeBrandsitem?.Brand?.file}`}
                                                                        alt="icon"
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        style={{
                                                                          width:
                                                                            "25px",
                                                                        }}
                                                                        src={
                                                                          ProductPlaceHolder
                                                                        }
                                                                        alt="icon"
                                                                      />
                                                                    )} */}
                                                                      <p>
                                                                        {
                                                                          subCatagoryTypeBrandsitem
                                                                            ?.Brand
                                                                            ?.name
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )
                                                                )}
                                                            </AccordionBody>
                                                          </>
                                                        )}
                                                        {subCategoryTypeItem
                                                          ?.CategoryBrands
                                                          ?.length < 1 && (
                                                          <>
                                                            <div
                                                              className="subCatagory-accordion-header-main"
                                                              onClick={() => {
                                                                dispatch(
                                                                  setCategoryDropdownValue(
                                                                    {
                                                                      state: {
                                                                        id: subCategoryTypeItem?.parentId,
                                                                        subCatId:
                                                                          subCategoryTypeItem?.subCatId,
                                                                        subCategoryTypeId:
                                                                          subCategoryTypeItem?.id,
                                                                        categoryName:
                                                                          category?.name,
                                                                        subCatagoryTypeName:
                                                                          subCategoryTypeItem?.name,
                                                                        subCatagoryName:
                                                                          subCatagoryItem?.name,
                                                                        brandName:
                                                                          "",
                                                                        SubCategory:
                                                                          category?.SubCategory,
                                                                      },
                                                                    }
                                                                  )
                                                                );
                                                                navigate(
                                                                  `/catalogue/${category.slug}/${subCatagoryItem?.slug}/${subCategoryTypeItem?.slug}`,
                                                                  {
                                                                    state: {
                                                                      id: subCategoryTypeItem?.parentId,
                                                                      subCatId:
                                                                        subCategoryTypeItem?.subCatId,
                                                                      subCategoryTypeId:
                                                                        subCategoryTypeItem?.id,
                                                                      categoryName:
                                                                        category?.name,
                                                                      subCatagoryTypeName:
                                                                        subCategoryTypeItem?.name,
                                                                      subCatagoryName:
                                                                        subCatagoryItem?.name,
                                                                      brandName:
                                                                        "",
                                                                      SubCategory:
                                                                        category?.SubCategory,
                                                                    },
                                                                  }
                                                                );
                                                              }}
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            >
                                                              <div className="subCatagory-accordion-header-inner">
                                                                <div className="subCatagory-accordion-title-block">
                                                                  {/* {subCategoryTypeItem.icon ? (
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                    src={`${url}/${subCategoryTypeItem.icon}`}
                                                                    alt="icon"
                                                                  />
                                                                ) : (
                                                                  <imgkk
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                    src={
                                                                      ProductPlaceHolder
                                                                    }
                                                                    alt="icon"
                                                                  />
                                                                )} */}
                                                                  <h3>
                                                                    {
                                                                      subCategoryTypeItem.name
                                                                    }
                                                                  </h3>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </AccordionItem>
                                                    )
                                                  )}
                                              </AccordionBody>
                                            </>
                                          )}

                                          {subCatagoryItem?.CategoryBrands
                                            ?.length < 1 &&
                                            subCatagoryItem?.SubCategoryType
                                              ?.length < 1 && (
                                              <>
                                                <div
                                                  className={`subCatagory-accordion-header-main`}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatch(
                                                      setCategoryDropdownValue({
                                                        state: {
                                                          id: subCatagoryItem?.parentId,
                                                          subCatId:
                                                            subCatagoryItem?.subCatId,
                                                          categoryName:
                                                            category?.name,
                                                          subCatagoryName:
                                                            subCatagoryItem?.name,
                                                          brandName: "",
                                                          SubCategory:
                                                            category?.SubCategory,
                                                        },
                                                      })
                                                    );
                                                    navigate(
                                                      `/catalogue/${category.slug}/${subCatagoryItem.slug}`,
                                                      {
                                                        state: {
                                                          id: subCatagoryItem?.parentId,
                                                          subCatId:
                                                            subCatagoryItem?.subCatId,
                                                          categoryName:
                                                            category?.name,
                                                          subCatagoryName:
                                                            subCatagoryItem?.name,
                                                          brandName: "",
                                                          SubCategory:
                                                            category?.SubCategory,
                                                        },
                                                      }
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <div className="subCatagory-accordion-header-inner">
                                                    <div className="subCatagory-accordion-title-block">
                                                      {subCatagoryItem.icon ? (
                                                        <img
                                                          style={{
                                                            width: "25px",
                                                          }}
                                                          src={`${imageCDN}/${subCatagoryItem.icon}`}
                                                          alt="icon"
                                                        />
                                                      ) : (
                                                        <img
                                                          style={{
                                                            width: "25px",
                                                          }}
                                                          src={
                                                            ProductPlaceHolder
                                                          }
                                                          alt="icon"
                                                        />
                                                      )}
                                                      <h3>
                                                        {subCatagoryItem.name}
                                                      </h3>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                        </>
                                      </AccordionItem>
                                    ))}

                                {category?.SubCategory?.length < 1 &&
                                  category?.SubCategoryType?.length < 1 &&
                                  category?.CategoryBrands?.length < 1 && (
                                    <p>No item found</p>
                                  )}
                              </div>
                            </AccordionBody>
                          </>
                        )}
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          )
        : (location?.pathname === "/" || location?.pathname === "/login") && (
            <div className="catagories__container__inner">
              <Accordion>
                {categoriesArray &&
                  categoriesArray?.map((category, index) => {
                    const bgColor = colorPalette[index % colorPalette.length];
                    return (
                      <AccordionItem key={category?.id}>
                        {({ open }) => (
                          <>
                            <AccordionHeader
                              className={`accordion-header-main ${
                                open ? "accordion-header-main-active" : ""
                              }`}
                            >
                              <div
                                style={{
                                  backgroundColor: bgColor,
                                  position: "relative",
                                }}
                                className="accordion-header-inner"
                                onMouseEnter={() => setOpenSideDropdown(index)}
                                onMouseLeave={() => setOpenSideDropdown("")}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    setCategoryDropdownValue({
                                      state: {
                                        id: category?.id,
                                        brandId: "",
                                        categoryName: category?.name,
                                        subCatagoryName: "",
                                        brandName: "",
                                      },
                                    })
                                  );
                                  navigate(`/catalogue/${category?.slug}`, {
                                    state: {
                                      id: category?.id,
                                      brandId: "",
                                      categoryName: category?.name,
                                      subCatagoryName: "",
                                      brandName: "",
                                      banner: category?.banner,
                                    },
                                  });
                                }}
                              >
                                <div className="accordion-title-block">
                                  {category?.icon ? (
                                    <div className="cat-list-icon-block">
                                      <img
                                        className="cat-list-icon"
                                        src={`${imageCDN}/${category?.file}`}
                                        alt="icon"
                                      />
                                    </div>
                                  ) : (
                                    <div className="cat-list-icon-block">
                                      <img
                                        className="cat-list-icon"
                                        src={ProductPlaceHolder}
                                        alt="icon"
                                      />
                                    </div>
                                  )}

                                  <h3>{category?.name}</h3>
                                </div>
                                <FaChevronRight height="15px" width="15px" />
                                {openSideDropdown === index &&
                                  category?.SubCategory?.length > 0 && (
                                    <SubCategoryDropdown
                                      SubCategory={category?.SubCategory}
                                      category={category}
                                    />
                                  )}
                              </div>
                            </AccordionHeader>
                          </>
                        )}
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          )}
      {!isSmallScreen &&
        (location?.pathname !== "/" || location?.pathname !== "/login") &&
        toogle && (
          <div
            className="catagories__container__inner"
            id="Main__Category__DrpDwn"
            style={{ zIndex: "10" }}
            // onClick={(e) => handleClick(e)}
          >
            <Accordion>
              {categoriesArray &&
                categoriesArray?.map((category, index) => {
                  const bgColor = colorPalette[index % colorPalette.length];
                  return (
                    <AccordionItem key={category?.id}>
                      {({ open }) => (
                        <>
                          <AccordionHeader
                            className={`accordion-header-main ${
                              open ? "accordion-header-main-active" : ""
                            }`}
                          >
                            <div
                              style={{
                                backgroundColor: bgColor,
                                position: "relative",
                              }}
                              className="accordion-header-inner"
                              onMouseEnter={() => setOpenSideDropdown(index)}
                              onMouseLeave={() => setOpenSideDropdown("")}
                              onClick={(e) => {
                                e.stopPropagation();

                                navigate(`/catalogue/${category?.slug}`);
                              }}
                            >
                              <div className="accordion-title-block">
                                {category?.icon ? (
                                  <div className="cat-list-icon-block">
                                    <img
                                      className="cat-list-icon"
                                      src={`${imageCDN}/${category?.file}`}
                                      alt="icon"
                                    />
                                  </div>
                                ) : (
                                  <div className="cat-list-icon-block">
                                    <img
                                      className="cat-list-icon"
                                      src={ProductPlaceHolder}
                                      alt="icon"
                                    />
                                  </div>
                                )}

                                <h3>{category?.name}</h3>
                              </div>
                              <FaChevronRight height="15px" width="15px" />
                              {openSideDropdown === index &&
                                category?.SubCategory?.length > 0 && (
                                  <SubCategoryDropdown
                                    SubCategory={category?.SubCategory}
                                    category={category}
                                  />
                                )}
                            </div>
                          </AccordionHeader>
                        </>
                      )}
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </div>
        )}
    </div>
  );
});

export default MainCategoriesDropdown;
