import { memo } from "react";
import { useDispatch } from "react-redux";
import "../../assets/scss/base/pages/account/AccountLinksTab.scss";
import "../../assets/scss/base/pages/global.scss";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../service/auth.service";
import Header from "../homepage/Header";
import { setLogout } from "../../redux/authSlice";
import { removeUserSession } from "../../utils/AuthService";
import {
  setCartList,
  setSelectedCartItemsId,
  setSelectedCartItemsList,
} from "../../redux/cartSlice";
import {
  setNotificationList,
  setPages,
  setTotalNotification,
} from "../../redux/notificationSlice";
import { setUserDetail } from "../../redux/userSlice";

const AccountLinksTab = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleChangePasswordLinkClick = () => {
    navigate("/account/changepassword");
  };

  const handleProfileClick = () => {
    navigate("/account/profile");
  };

  const handleEInvoiceClick = () => {
    navigate("/account/e-invoice");
  };

  /**
   * @function handleLogOutClick
   * @params
   * @use used for logging out user
   */

  const handleLogOutClick = async (e) => {
    e.preventDefault();
    const response = await logout();
    if (response?.data?.success) {
      dispatch(setLogout(false));
      removeUserSession();
      dispatch(setUserDetail(" "));
      dispatch(setCartList([]));
      dispatch(setSelectedCartItemsList([]));
      dispatch(setSelectedCartItemsId([]));
      dispatch(setNotificationList([]));
      dispatch(setPages(0));
      dispatch(setTotalNotification(0));
      navigate("/");
    }
  };
  //Account Tab Link
  return (
    <div className="frame-parent1">
      <button
        className="overview-wrapper"
        onClick={() => navigate("/account/overview")}
      >
        <div
          className={
            location.pathname === "/account/overview" ? "activeTab" : "orders"
          }
        >
          Overview
        </div>
      </button>
      <div onClick={handleProfileClick} className="profile-wrapper">
        <div
          className={
            location.pathname == "/account/profile" ? "activeTab" : "orders"
          }
        >
          Profile
        </div>
      </div>
      <div onClick={handleEInvoiceClick} className="e-invoice-wrapper">
        <div
          className={
            location.pathname == "/account/e-invoice" ? "activeTab" : "orders"
          }
        >
          E-Invoice
        </div>
      </div>
      <button
        className="PCoin-wrapper"
        onClick={() => navigate("/account/P-Coin")}
      >
        <div
          className={
            location.pathname == "/account/P-Coin" ? "activeTab" : "orders"
          }
        >
          P-Coins
        </div>
      </button>
      <button className="DailyLogin-wrapper">
        <div
          className={
            location.pathname == "/account/dailyLoginBonus"
              ? "activeTab"
              : "orders"
          }
          onClick={() => navigate("/account/dailyLoginBonus")}
        >
          Daily Login Bonus
        </div>
      </button>

      <button className="order-wrapper">
        <div
          className={
            location.pathname == ("/account/orders" || "/account/order")
              ? "activeTab"
              : "orders"
          }
          onClick={() => navigate("/account/orders")}
        >
          Orders
        </div>
      </button>

      <div className="Bottom__Container">
        {/* <button className="shop-wrapper">
          <div className="orders">Shop Purchase</div>
        </button> */}
        <button className="">
          <div
            className={
              location.pathname === "/account/addressBook"
                ? "activeTab"
                : "orders"
            }
            onClick={() => navigate("/account/addressBook")}
          >
            Address Book
          </div>
        </button>
        <button
          onClick={handleChangePasswordLinkClick}
          className="ChangePass-wrapper"
        >
          <div
            className={
              location.pathname === "/account/changepassword"
                ? "activeTab"
                : "orders"
            }
          >
            Change Password
          </div>
        </button>
      </div>
      <button onClick={handleLogOutClick} className="log-out-03-parent">
        <HiOutlineLogout className="chevron-right-icon" />
        <div className="overview">Log Out</div>
      </button>
    </div>
  );
});

export default AccountLinksTab;
