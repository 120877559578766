import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button } from "reactstrap";

import "../../assets/scss/base/pages/deleteAccount/deleteAccount.scss";
import toast, { Toaster } from "react-hot-toast";

// images
import Logo from "../../assets/images/homepage/logo-large.png";
import FeaturedIcon from "../../assets/images/homepage/Featured-icon.png";
import Cross from "../../assets/images/homepage/Remove-User-Cross.png";
import SuccessIcon from "../../assets/images/homepage/user-delete-account-success.png";
import AlertCircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import VideoThubanail from "../../assets/images/homepage/Video-Thumbanail.png";
import {
  OtpVerifyDeleteService,
  deleteUserAccAppStoreService,
} from "../../service/user.service";
import { removeUserSession, setUserSession } from "../../utils/AuthService";

const DeleteAccount = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [OTPModal, setOTPModal] = useState(false);
  const [conferMationModal, setConferMationModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const [mobileNo, setMobileNo] = useState();
  const [otp, setOtp] = useState({
    firstNumber: "",
    secondNumber: "",
    thirdNumber: "",
    LastNumber: "",
  });
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [otpSent, setOTPSent] = useState(false);
  const [otpError, setOtpError] = useState();

  const otpCode = `${otp.firstNumber}${otp.secondNumber}${otp.thirdNumber}${otp.LastNumber}`;
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    const response = await OtpVerifyDeleteService({
      otp: otpCode,
      otpType: "Delete Account",
    });
    if (response.data.success) {
      conferMationToggle();
      OTPModalToggle();
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              {/* <p>This product is not added to wishlist</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  //      const calculateElapsedTime = () => {
  //     if (!lastRequestTime) return 0;
  //     const currentTime = new Date().getTime();
  //     return (currentTime - lastRequestTime) / 1000; // Convert to seconds
  //   };

  //   const startCountdown = () => {
  //     let timeLeft = 60 - Math.floor(calculateElapsedTime());
  //     setRemainingTime(timeLeft);

  //     const interval = setInterval(() => {
  //       timeLeft -= 1;
  //       if (timeLeft >= 0) {
  //         setRemainingTime(timeLeft);
  //         setOtpError(
  //           `Please wait for ${timeLeft} seconds before resending OTP.`
  //         );
  //       } else {
  //         clearInterval(interval);
  //         setOtpError("");
  //       }
  //     }, 1000); // Update every second
  //   };
  const handleResendOTP = async (e) => {
    e.preventDefault();

    handleSendOTP(e);
  };
  useEffect(() => {
    setLastRequestTime(new Date().getTime());
  }, []);
  const handleSendOTP = async (e) => {
    e?.preventDefault();
    const response = await deleteUserAccAppStoreService({
      mobileNo: mobileNo,
    });
    if (response?.data?.success) {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Successfully sent otp.</h4>
              <p>Please check your Phone/Email.</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
      const tokens = {
        tokenAccess: response.data.data.token,
        foodAccessToken: response.data.data.foodToken,
      };
      setUserSession(tokens);
      setOTPModal(true);
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={AlertCircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              {/* <p>This product is not added to wishlist</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  const OTPModalToggle = () => setOTPModal(!OTPModal);
  const conferMationToggle = () => setConferMationModal(!conferMationModal);
  const successfulModalToggle = () => setSuccessfulModal(!successfulModal);

  const handleCancel = () => {
    setOTPModal(false);
    setConferMationModal(false);
    setSuccessfulModal(false);
  };

  return (
    <div className="delete-account-page-wraper">
      <div className="delete-account-wraper">
        <div className="delete-account-header-block">
          <img src={Logo} alt="" />
          <h3>Pineapple.my Web and Mobile Application</h3>
        </div>
        {!OTPModal && !conferMationModal && !successfulModal && (
          <>
            <h2 className="delete-account-page-title">
              Pineapple.my Account Deletion
            </h2>
            <div className="delete-account-content-wrap">
              <div className="delete-account-content-box">
                <h3>These data will be deleted when you delete the account</h3>
                <ul>
                  <li>Available P-Coins</li>
                  <li>Daily Login Bonus</li>
                  <li>Order History</li>
                  <li>Digital Codes</li>
                  <li>Vouchers</li>
                  <li>My Wishlist</li>
                  <li>Purchased Offers (Services)</li>
                  <li>Profile</li>
                </ul>
              </div>
              <div className="delete-account-content-box">
                <h3 className="delete-account-content-title">
                  Enter your Registered{" "}
                  <span className="delete-account-content-primary-color">
                    phone number
                  </span>{" "}
                  or{" "}
                  <span className="delete-account-content-primary-color">
                    Email address
                  </span>{" "}
                  to delete your Pineapple.my account
                </h3>
                <Form style={{ position: "relative" }}>
                  {!isNaN(mobileNo) && <p className="Country__Code">+6</p>}
                  <Input
                    type="text"
                    placeholder="Enter here email or phone number"
                    onChange={(e) => setMobileNo(e.target.value)}
                    className={!isNaN(mobileNo) && "Input__Field"}
                  />
                  <Button onClick={handleSendOTP}>Verify</Button>
                </Form>
              </div>
            </div>
          </>
        )}
        {OTPModal && (
          <div className="delete-account-verification-code-modal-body delete-account-modal">
            <img
              src={Cross}
              alt=""
              onClick={handleCancel}
              className="modal-close-btn"
            />
            <div className="delete-account-verification-code-title-block">
              <img src={FeaturedIcon} alt="" />
              <h4>Please check your Message</h4>
              <p>
                We've sent a code to{" "}
                <span>{!isNaN(mobileNo) ? `+6 ${mobileNo}` : `${mobileNo}`}</span>
              </p>
            </div>
            <div className="verification-code-input-field">
              <div className="input6">
                <input
                  className="mega-input-field-base"
                  type="number"
                  ref={inputRefs[0]}
                  min={0}
                  max={9}
                  onChange={(e) => {
                    setOtp({ ...otp, firstNumber: e.target.value });
                    inputRefs[0 + 1].current.focus();
                  }}
                  placeholder="-"
                />
                <input
                  className="mega-input-field-base"
                  type="number"
                  ref={inputRefs[1]}
                  min={0}
                  max={9}
                  onChange={(e) => {
                    setOtp({ ...otp, secondNumber: e.target.value });
                    inputRefs[1 + 1].current.focus();
                  }}
                  placeholder="-"
                />
                <input
                  className="mega-input-field-base"
                  type="number"
                  ref={inputRefs[2]}
                  min={0}
                  max={9}
                  onChange={(e) => {
                    setOtp({ ...otp, thirdNumber: e.target.value });
                    inputRefs[2 + 1].current.focus();
                  }}
                  placeholder="-"
                />
                <input
                  className="mega-input-field-base"
                  type="number"
                  ref={inputRefs[3]}
                  min={0}
                  max={9}
                  onChange={(e) => {
                    setOtp({ ...otp, LastNumber: e.target.value });
                  }}
                  placeholder="-"
                />
              </div>
              {otpError && (
                <p
                  style={{
                    fontSize: "14px",
                    color: "#D71513",
                    textAlign: "left",
                  }}
                >
                  {otpError}
                </p>
              )}
            </div>
            <p className="resend-text">
              Didn’t get a code?{" "}
              <span onClick={(e) => handleResendOTP(e)}>Click to resend.</span>
            </p>
            <div className="delete-account-btn-group">
              <button onClick={handleCancel} className="cancel-btn">
                Cancel
              </button>
              <button
                onClick={(e) => {
                  handleOtpVerify(e);
                }}
                className="verify-btn"
              >
                Verify
              </button>
            </div>
          </div>
        )}
        {conferMationModal && (
          <div className="delete-account-confermation-modal-body delete-account-modal">
            <img
              src={Cross}
              alt=""
              onClick={handleCancel}
              className="modal-close-btn"
            />

            <h2>Delete Account?</h2>
            <p>
              Deleting your account will remove all of your information from our
              database. This cannot be undone.
            </p>

            <div className="delete-account-btn-group">
              <button onClick={handleCancel} className="cancel-btn">
                Cancel
              </button>
              <button
                onClick={() => {
                  conferMationToggle();
                  successfulModalToggle();
                }}
                className="verify-btn"
              >
                Delete Account
              </button>
            </div>
          </div>
        )}
        {successfulModal && (
          <div className="delete-account-success-modal-body delete-account-modal">
            <img
              src={Cross}
              alt=""
              onClick={handleCancel}
              className="modal-close-btn"
            />

            <img
              src={SuccessIcon}
              alt=""
              className="delete-account-success-modal-icon"
            />
            <h2>Successful!</h2>
            <p>You have successfully deleted your P-coin account.</p>
          </div>
        )}
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default DeleteAccount;
