import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// API-Service
import { forgetPassword } from "../../service/auth.service";
// Utility-Service
import { setUserSession } from "../../utils/AuthService";
// Redux-Settings
import { setTemporaryPhoneNumber } from "../../redux/utilitySlice";
import { logo } from "../../assets/images/authentication";
import "../../assets/scss/base/pages/authentication/ForgotPassword.scss";
import "../../assets/scss/base/pages/index.scss";
import { setLoginModalToggle } from "../../redux/modalsSlice";
import { setLogin } from "../../redux/authSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [isPhoneRight, setIsPhoneRight] = useState("");
  const [phoneLengthError, setPhoneLengthError] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState("");
  const [notValidated, setNotValidated] = useState(false);
  const [isPhoneCodeToShow, setIsPhoneCodeToShow] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handlePhoneInput = (e) => {
    const phoneValue = e.target.value;

    setPhoneLengthError(false);
    setPhone(phoneValue);

    setIsPhoneRight("");
    if (!isNaN(e.target.value)) {
      setIsPhoneCodeToShow(true);
      if (phoneValue.length >= 9 && phoneValue.length <= 11) {
        setPhone(phoneValue);
        setPhoneInputError("");
      } else {
        setPhone(phoneValue);
        setPhoneInputError("Phone number must be between 9 and 11 digits.");
      }
      if (phoneValue.length < 1) {
        setPhoneInputError("");
      }
    } else {
      setPhoneInputError("");
      setIsPhoneCodeToShow(false);

      if (!isValidEmail(phoneValue)) {
        setNotValidated(true);
      } else {
        setNotValidated(false);
      }
    }
    if (!phoneValue) {
      setNotValidated(false);
      setIsPhoneCodeToShow(false);
    }
  };

  /**
   * @function handleSubmit
   * @param event
   * @description This function is used to send otp to phone number
   */

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phone?.length < 1) {
      setPhoneLengthError(true);
      return false;
    }

    const result = await forgetPassword({
      mobileNo: phone,
      userType: "user",
    });

    if (result?.data?.success) {
      dispatch(setTemporaryPhoneNumber(phone));
      const tokens = {
        foodAccessToken: result?.data?.data?.foodToken,
        tokenAccess: result?.data?.data?.token,
      };
      const user = "";
      setUserSession(tokens, user);
      navigate("/otpverification", {
        state: {
          type: "Forgot Password",
          btnText: "Verify",
          phoneNumber: phone,
        },
      });
    } else if (result?.data?.errorMessage) {
      setIsPhoneRight(result?.data?.errorMessage);
    }
  };

  const handleSignInClick = () => {
    navigate("/");
    dispatch(setLoginModalToggle(true));
  };

  return (
    <div className="forgot-password">
      <form className="content5" method="post">
        <div className="header2">
          <img
            className="logo-1-icon"
            alt="logo"
            onClick={() => navigate("/")}
            src={logo}
          />
          <div className="text-group">
            <div className="text16">Forgot Password?</div>
            <div className="label-text1">
              No worries, it happens to the best of us. Just enter the phone
              number linked with your account.
            </div>
          </div>
        </div>
        <div className="content6">
          <div className="form1">
            <div className="input-field2">
              <div className="input-field2">
                <div className="label3">Phone number/E-mail</div>
                <div className="phone-div">
                  {isPhoneCodeToShow && (
                    <input className="prefix-phone" value="+6" readOnly />
                  )}
                  <input
                    // style={{
                    //   borderLeft: "1px solid #d0d5dd",
                    //   borderRadius: "5px",
                    // }}
                    className={isPhoneCodeToShow ? "input9 phone" : "input2"}
                    type="text"
                    onChange={(e) => handlePhoneInput(e)}
                    placeholder="Enter your phone number or e-mail"
                  />
                </div>
                {notValidated ? (
                  <span className="hint-text4-email">
                    Enter valid email address
                  </span>
                ) : null}
                {isPhoneRight && <p className="hintpass">{isPhoneRight}</p>}
                {phoneInputError && (
                  <span className="confirm-password-check-red">
                    {phoneInputError}
                  </span>
                )}
                {phoneLengthError && (
                  <span className="confirm-password-check-red">
                    Phone number must not empty
                  </span>
                )}
              </div>
            </div>
          </div>
          <button onClick={handleSubmit} className="actions2">
            <div className="buttons6">
              <div className="text17">Get Verification code</div>
            </div>
          </button>
          <div className="remember-password-sign-container">
            <span className="remember-password1">
              <span className="remember-password2">Remember Password?</span>
              <span className="span">{` `}</span>
            </span>
            <button onClick={handleSignInClick} className="span">
              <span className="sign-in1">Sign In</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
