import { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
// API Service
import {
  paymentMethodListService,
  getShippingCostService,
} from "../../service/cart.service";
import { getUserProfile } from "../../service/user.service";

// Redux-Settings
import {
  selectSelectedCartItemsList,
  selectCartVendorList,
  setShipingCost,
  selectShipingCost,
  setWeight,
  setPaymentMethod,
  setRequestEInvoice,
  setEInvoiceType,
  selectPaymentMethod,
  selectRequestEInvoice,
  selectEInvoiceType,
  setShippingEnabled,
  setSelfPickup,
  selectSelfPickup,
  setDiscountAmount,
  setRedeemedpCoin,
  setIsAppliedPCoin,
  setpCoinDiscountAmount,
  setCouponContent,
  setIsAppliedCoupon,
  setCouponDiscountAmount,
  selectCouponType,
} from "../../redux/cartSlice";
import {
  selectDefaultShippingAddressId,
  selectShippingAddressList,
} from "../../redux/addressSlice";
// Images
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
// Utility
import { priceCalculator } from "../../utils/Utility";
// Components
import RemoveCartModal from "./Modals/RemoveCartModal";

// Styles
import "../../assets/scss/base/pages/cart/OrderedItemsList.scss";
import { selectPCoinValue } from "../../redux/utilitySlice";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const FinalOrderedItemsList = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defultShippingID = useSelector(selectDefaultShippingAddressId);
  const shippingAddressList = useSelector(selectShippingAddressList);
  const perPCoinValue = useSelector(selectPCoinValue);
  const cartVendorItems = useSelector(selectCartVendorList);

  const selectedPaymentMethod = useSelector(selectPaymentMethod);
  const isSelfPickup = useSelector(selectSelfPickup);
  const couponType = useSelector(selectCouponType);
  const requestE_invoice = useSelector(selectRequestEInvoice);
  const E_invoiceType = useSelector(selectEInvoiceType);

  const [user, setUser] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const shipingCost = useSelector(selectShipingCost);
  // Modal-Controll
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(!isOpenModal);

  // Cart-Item Select Settings
  const selectedCartItemsList = useSelector(selectSelectedCartItemsList);

  // Fetch payment method
  const fetchPaymentMethodList = async () => {
    const response = await paymentMethodListService({
      getAll: true,
      filter: "Active",
    });
    if (response?.data?.success) {
      setPaymentMethods(response?.data?.data?.list);
    }
  };

  useEffect(() => {
    fetchPaymentMethodList();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await getUserProfile();

      if (response?.data?.success) {
        setUser(response?.data?.data);
      } else {
        console.log(response?.data?.errorMessage);
      }
    };

    fetchProfile();
  }, []);

  return (
    <div className="frame-parent11OrderedItemsList" style={{ height: '880px', overflowY: 'auto' }}>
      <div className="checkbox-base-parentOrderedItemsList">
        <div className="items-selectedOrderedItemsList">Order Overview</div>
      </div>
      {selectedCartItemsList?.length > 0 && cartVendorItems?.length > 0 &&
        <div className="d-flex align-items-center bg-white p-2">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 8.65685 16.3431 10 18 10C19.6569 10 21 8.65685 21 7M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#D71513" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p className="fw-bold vendor-name h6 ms-2 mb-0">{cartVendorItems?.find(item => item?.items?.some(product => selectedCartItemsList?.some(cartItem => cartItem?.id === product?.id)))?.vendor?.companyName || "Pineapple.my"}</p>
        </div>
      }
      {selectedCartItemsList?.map((cartItem, index) => (
        <div key={cartItem?.id} className="frame-parent14OrderedItemsList">
          <div
            // onClick={() => {
            //   navigate(
            //     `/product/${cartItem?.Product?.id}/${cartItem?.Product?.name
            //       ?.trim()
            //       ?.replace(/ /g, "-")}`,
            //     { state: { productId: cartItem?.Product?.id } }
            //   );
            // }}
            className="hp-14-dv2029tu-nb-gold-photo3-wrapperOrderedItemsList"
          >
            {cartItem?.Product?.thumbnail ? (
              <img
                className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                alt="item"
                src={`${imageCDN}/${cartItem?.Product?.thumbnail}`}
              />
            ) : (
              <img
                className="hp-14-dv2029tu-nb-gold-photo3-iconOrderedItemsList"
                alt="item"
                src={ProductPlaceHolder}
              />
            )}
          </div>
          <div className="frame-parent15OrderedItemsList">
            <div className="hp-pavilion-14-dv2029tu-i5-123-parentOrderedItemsList">
              <div
                // onClick={() => {
                //   navigate(
                //     `/product/${cartItem?.Product?.id}/${cartItem?.Product?.name
                //       ?.trim()
                //       ?.replace(/ /g, "-")}`,
                //     { state: { productId: cartItem?.Product?.id } }
                //   );
                // }}
                className="hp-pavilion-14-dv2029tuOrderedItemsList"
              >
                {cartItem?.Product?.name}
              </div>
              <div
                className="parentOrderedItemsList"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="rm290900OrderedItemsList"
                  style={{ display: "flex", fontWeight: "700" }}
                >
                  <p style={{ fontSize: "11px" }}>RM</p>
                  {Number(
                    priceCalculator({
                      price: cartItem?.ProductVariation?.price,
                      discountPrice: cartItem?.ProductVariation?.discount,
                    })
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                {cartItem?.ProductVariation?.discount > 0 && (
                  <div
                    className="divOrderedItemsList"
                    style={{ fontSize: "11px", marginTop: "3px" }}
                  >
                    RM
                    {cartItem?.ProductVariation?.price.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                )}

                <div
                  className="offOrderedItemsList"
                  style={{ fontSize: "11px", marginTop: "3px" }}
                >
                  {cartItem?.ProductVariation?.discount > 0 &&
                    Math.floor(cartItem?.ProductVariation?.discount) + "% OFF"}
                </div>
              </div>
            </div>

            <div className="frame-parent13OrderedItemsList">
              {cartItem?.ProductVariation?.attibutes?.Color !== "Default" &&
                cartItem?.ProductVariation?.attibutes?.Color !== "other" && (
                  <div className="coloringDivOrderedItemsList">
                    <p className="colorTitleOrderedItemsList">Color:</p>
                    <div
                      style={{
                        border: "2px solid #d3d3d3",
                        height: 20,
                        width: 20,
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: 16,
                          width: 16,
                          borderRadius: 20,
                          backgroundColor: cartItem?.ProductVariation?.attibutes
                            ?.Color
                            ? cartItem?.ProductVariation?.attibutes?.Color?.toLowerCase()
                            : cartItem?.ProductVariation?.attibutes?.color?.toLowerCase(),
                        }}
                      />
                    </div>
                    <p style={{ textTransform: "capitalize" }}>
                      {cartItem?.ProductVariation?.attibutes?.Color
                        ? cartItem?.ProductVariation?.attibutes?.Color
                        : cartItem?.ProductVariation?.attibutes?.color}
                    </p>
                  </div>
                )}

              <div className="coloringDivOrderedItemsList">
                <p className="colorTitleOrderedItemsList">Size:</p>
                <p>
                  {cartItem?.ProductVariation?.attibutes?.Size
                    ? cartItem?.ProductVariation?.attibutes?.Size
                    : null}
                  {cartItem?.ProductVariation?.attibutes?.storage
                    ? cartItem?.ProductVariation?.attibutes?.storage
                    : null}
                </p>
              </div>
            </div>
            <div className="quantity-1-parentOrderedItemsList">
              <div className="blackOrderedItemsList">
                Quantity :
                <span className="product__quantity">{cartItem?.quantity}</span>
              </div>
              <img
                className="vector-iconOrderedItemsList"
                alt=""
                src="/vector-1711.svg"
              />
            </div>
            {/* <div style={{ marginTop: "0.2rem" }}>
              <p>
                SKU :{" "}
                <span style={{ color: "grey" }}>
                  {cartItem?.ProductVariation?.sku}
                </span>
              </p>
            </div> */}
            <p className="Cart__Item__PCoin">
              Redeemable Pcoins :{" "}
              {priceCalculator({
                price: cartItem?.ProductVariation?.price,
                discountPrice: cartItem?.ProductVariation?.discount,
              }) *
                perPCoinValue <
                cartItem?.ProductVariation?.redeemPcoinsLimit
                ? parseFloat(
                  priceCalculator({
                    price: cartItem?.ProductVariation?.price,
                    discountPrice: cartItem?.ProductVariation?.discount,
                  }) * perPCoinValue
                ) * cartItem?.quantity
                : cartItem?.ProductVariation?.redeemPcoinsLimit *
                cartItem?.quantity}
            </p>
          </div>
          {isOpenModal && (
            <RemoveCartModal
              isOpenModal={isOpenModal}
              toggle={toggle}
              data={cartItem?.id}
            />
          )}
        </div>
      ))}
      <div className="cart__payment__option__block">
        <div className="cart__payment__option__title">
          <h3>Payment Method</h3>
        </div>
        <>
          {paymentMethods?.map((payMentMethodItem) => (
            <div
              key={payMentMethodItem?.id}
              className="cart__payment__option__wrap"
            >
              <FormGroup check>
                <Input
                  name="paymentMethod"
                  id="isDefaultAsShippingAddress"
                  type="checkbox"
                  onClick={() =>
                    dispatch(setPaymentMethod(payMentMethodItem?.id))
                  }
                  checked={selectedPaymentMethod == payMentMethodItem?.id}
                />
                <Label for="isDefaultAsShippingAddress" check>
                  <div className="cart__payment__option__content__block">
                    <p>{payMentMethodItem?.name}</p>
                    <img
                      src={`${imageCDN}/${payMentMethodItem?.file}`}
                      alt=""
                      style={{ height: "100%" }}
                    />
                  </div>
                </Label>
              </FormGroup>
            </div>
          ))}
        </>
      </div>

      <hr className="w-100 my-0" />

      <div className="cart__payment__option__block">
        <div className="cart__payment__option__title">
          <FormGroup
            check
            inline
          >
            <Input
              type="checkbox"
              onClick={(e) => {
                if (!e?.target?.checked) {
                  dispatch(setEInvoiceType(""))
                } else {
                  dispatch(setEInvoiceType("Individual"))
                }
                dispatch(setRequestEInvoice(e?.target?.checked))
              }}
              checked={requestE_invoice}
            />
            <Label check>
              <h3>Request E-Invoice</h3>
            </Label>
          </FormGroup>
        </div>
        {requestE_invoice &&
          <>
            <div
              className="cart__payment__option__wrap"
            >
              <FormGroup check>
                <Input
                  name="Individual"
                  id="Individual"
                  type="checkbox"
                  onClick={() =>
                    dispatch(setEInvoiceType("Individual"))
                  }
                  checked={E_invoiceType == "Individual"}
                />
                <Label for="E_invoiceType" check>
                  <div className="cart__payment__option__content__block">
                    <p>Individual</p>
                  </div>
                </Label>
              </FormGroup>
            </div>
            {E_invoiceType === "Individual" && !user?.IcFullName && !user?.IcNumber &&
              <Link className="d-flex justify-content-between align-items-center text-danger text-decoration-none px-2" to="/account/e-invoice" style={{
                background: "var(--grey-50, #F9FAFB)"
              }}>
                <p className="cart__einvoice__content">Please complete the E-invoice section in your profile.</p>
                <IoIosArrowForward size={16} />
              </Link>
            }
            <div
              className="cart__payment__option__wrap"
            >
              <FormGroup check>
                <Input
                  name="Company"
                  id="Company"
                  type="checkbox"
                  onClick={() =>
                    dispatch(setEInvoiceType("Company"))
                  }
                  checked={E_invoiceType == "Company"}
                />
                <Label for="isDefaultAsShippingAddress" check>
                  <div className="cart__payment__option__content__block">
                    <p>Company</p>
                  </div>
                </Label>
              </FormGroup>
            </div>
            {E_invoiceType === "Company" && !user?.UserCompnayInfo &&
              <Link className="d-flex justify-content-between align-items-center text-danger text-decoration-none px-2" to="/account/e-invoice" style={{
                background: "var(--grey-50, #F9FAFB)"
              }}>
                <p className="cart__einvoice__content">Please complete the E-invoice section in your profile.</p>
                <IoIosArrowForward size={16} />
              </Link>
            }
          </>
        }
      </div>

      <hr className="w-100 my-0" />

      <div className="cart__payment__option__block">
        <div className="cart__payment__option__title">
          <h3>Shipping Cost</h3>
        </div>
        <div className="cart__payment__option__wrap">
          <FormGroup check>
            <Input
              name="paymentMethod"
              id="isDefaultAsShippingAddress"
              type="checkbox"
              checked={isSelfPickup ? false : true}
              onChange={() => dispatch(setSelfPickup(false))}
            />
            <Label for="isDefaultAsShippingAddress" check>
              <div className="cart__payment__option__content__block">
                <p>Shipping Rate RM {shipingCost.toFixed(2)}</p>
              </div>
            </Label>
          </FormGroup>
        </div>
      </div>
      <div
        className="cart__payment__option__block"
        style={{ marginTop: "-0px" }}
      >
        <div className="cart__payment__option__wrap">
          <FormGroup check>
            <Input
              name="paymentMethod"
              id="isDefaultAsShippingAddress"
              type="checkbox"
              checked={isSelfPickup ? true : false}
              onChange={() => {
                dispatch(setSelfPickup(true));
                if (couponType == "Free Shipping") {
                  dispatch(setDiscountAmount(0));
                  dispatch(setCouponContent(""));
                  dispatch(setIsAppliedCoupon(false));
                  dispatch(setCouponDiscountAmount(0));
                }
              }}
            />
            <Label for="isDefaultAsShippingAddress" check>
              <div className="cart__payment__option__content__block">
                <p>Self-Collect from Wisma Pineapple</p>
              </div>
            </Label>
          </FormGroup>
        </div>
      </div>
    </div>
  );
});

export default FinalOrderedItemsList;
