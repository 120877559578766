import React, { useState } from 'react';

const ShowMoreLessText = ({ text, maxLength = 100 }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const displayText = showFullText ? text : `${text.slice(0, maxLength)}...`;

  return (
    <div>
      <p>{displayText}</p>
        {text.length > maxLength && (
            <button onClick={toggleText}>
            {showFullText ? 'Less' : '+ More'}
            </button>
        )}
    </div>
  );
};

export default ShowMoreLessText;