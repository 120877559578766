/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

//components
import OrderedItem from "../../components/SingleOnGoingOrder/OrderedItem";
//styles
import "../../assets/scss/base/pages/SingleOngoingOrder/SingleOngoingOrderPage/SingleOngoingOrderPage.scss";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
//images
import DeliveredTick from "../../assets/images/account/Order/DeliveredTick.svg";
import PCoin from "../../assets/images/account/Order/PCoin.svg";
import FileIcon from "../../assets/images/fileIcon.svg";
//services
import { OrderDetailService } from "../../service/OrderList.service";
import RateReviewModal from "../../components/order/Modal/RateReviewModal";
import SuccessMessageModal from "../../components/order/Modal/SuccessMessageModal";
import ProductTracker from "../../components/ProductTracker/ProductTracker";
import Loader from "../../components/micro/loader/Loader";
import { priceCalculator } from "../../utils/Utility";
import { Helmet } from "react-helmet";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

const SingleOngoingOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [orderedItem, setOrderedItem] = useState();
  const [Total, setTotal] = useState();
  const [totalProductPrice, setTotalProductPrice] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isOpenSuccesModal, setIsOpenSuccessModal] = useState(false);
  const totalOrderAmount = () => {
    let totalPrice = 0;
    let grandTotal = 0;
    for (let i = 0; i < orderedItem?.OrderItems?.length; i++) {
      totalPrice = totalPrice + Number(orderedItem?.OrderItems[i]?.price);

      setTotal(totalPrice);
    }
    grandTotal =
      orderedItem?.total +
      Number(orderedItem?.shippingCost) -
      (Number(orderedItem?.discountOnProduct) +
        Number(orderedItem?.couponDiscount) +
        Number(orderedItem?.voucherDiscount) +
        Number(orderedItem?.redeemedPcoinValue));
    setTotalProductPrice(grandTotal);
  };

  const fetchOrderDetail = async () => {
    const response = await OrderDetailService({ id: id });
    setOrderedItem(response?.data?.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchOrderDetail();
  }, [isOpenSuccesModal]);
  useEffect(() => {
    totalOrderAmount();
  });
  //Order Tracker Function

  const OrderTracker = (e, trackingID) => {
    e.preventDefault();

    TrackButton.track({
      tracking_no: trackingID,
    });
  };

  return (
    <AccountMenu>
      <Helmet>
        <meta charSet="utf-8" />
        <title > Account | Order Detail</title>
        <meta
          name="description"
          content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
        />
      </Helmet>
      {isLoading ? (
        <div style={{ width: "100%" }}>
          <Loader />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <h4>Order ID : #PC000{id}</h4>

            {orderedItem?.invoice && (
              <p
                className="Invoice__Btn"
                onClick={() => window.open(`${url}/${orderedItem?.invoice}`)}
              >
                <img src={FileIcon} alt="File-Icon" /> Invoice
              </p>
            )}
          </div>
          {orderedItem?.status === "Payment Failed" && (
            <div>
              <span style={{ fontFamily: "Poppins", color: "#d71513" }}>
                {orderedItem?.status}
              </span>
            </div>
          )}

          <div>
            <div className="d-flex align-items-center bg-white p-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 8.65685 16.3431 10 18 10C19.6569 10 21 8.65685 21 7M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#D71513" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p className="fw-bold ms-2">{orderedItem?.Vendor?.companyName || "Pineapple.my"}</p>
            </div>
            {orderedItem?.OrderItems?.map((item, index) => {
              return (
                <OrderedItem
                  orderedItem={item}
                  setOpenModal={setOpenModal}
                  deliverystatus={orderedItem?.deliverystatus?.delivered?.done}
                  key={index}
                  setSelectedProduct={setSelectedProduct}
                />
              );
            })}
          </div>

          {orderedItem?.deliverystatus?.delivered?.done ? (
            <>
              <div className="Delivered__OrderPage__Block">
                <img src={DeliveredTick} alt="delivered-icon" />
                <div>
                  <h6 style={{ fontFamily: "Poppins" }}>Delivered </h6>
                  <p>
                    on{" "}
                    {moment
                      .unix(orderedItem?.deliverystatus?.delivered?.date)
                      .format("MMM, Do YYYY")}
                  </p>
                </div>
              </div>
            </>
          ) : (
            orderedItem?.status !== "Payment Failed" && (
              <ProductTracker deliverystatus={orderedItem?.deliverystatus} />
            )
          )}
          <div className="SingleOngoingOrder__page__RewardSec">
            Your Rewards on this order <br />
            <img src={PCoin} alt="PCoin" className="PCoin_Icon" />
            {orderedItem?.earnedPcoin} P-Coins
          </div>
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h6 className="SingleOngoingOrder__page__Header">
              Shipping Address
            </h6>
            <h4 className="SingleOngoingOrder__page__Title">
              {orderedItem?.shippingAddress?.receiptient}
            </h4>
            <p className="SingleOngoingOrder__page__Desc">
              {orderedItem?.shippingAddress?.company}              <br />
              {orderedItem?.shippingAddress?.tin}  <br />
              {orderedItem?.shippingAddress?.address},
              {orderedItem?.shippingAddress?.city}
              <br />
              {orderedItem?.shippingAddress?.state},
              {orderedItem?.shippingAddress?.country}-
              {orderedItem?.shippingAddress?.zipCode}


            </p>
            <div className="SingleOngoingOrder__page__ContactDesc">
              <p className="SingleOngoingOrder__page__ContactDesc__Header">
                Mobile:
              </p>
              <p>+6 {orderedItem?.shippingAddress?.mobile}</p>
            </div>
          </div>
          {orderedItem?.billingAddress && (
            <div className="SingleOngoingOrder__page__ShipAddressSec">
              <h6 className="SingleOngoingOrder__page__Header">
                Billing Address
              </h6>
              <h4 className="SingleOngoingOrder__page__Title">
                {orderedItem?.billingAddress?.receiptient}
              </h4>
              <p className="SingleOngoingOrder__page__Desc">
                {orderedItem?.billingAddress?.company} <br />
                {orderedItem?.billingAddress?.tin} <br />
                {orderedItem?.billingAddress?.address},
                {orderedItem?.billingAddress?.city}
                <br />
                {orderedItem?.billingAddress?.state},
                {orderedItem?.billingAddress?.country}-
                {orderedItem?.billingAddress?.zipCode}


              </p>
              <div className="SingleOngoingOrder__page__ContactDesc">
                <p className="SingleOngoingOrder__page__ContactDesc__Header">
                  Mobile:
                </p>
                <p>+6 {orderedItem?.billingAddress?.mobile}</p>
              </div>
            </div>
          )}
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h4 className="SingleOngoingOrder__page__Header">Bill Details</h4>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">Sub Total</p>
              <p className="SingleOngoingOrder__page__DescValue">
                RM{" "}
                {/* {priceCalculator({
                  price: totalProductPrice,
                  discountPrice: totalProductDiscount,
                }) + orderedItem?.discountOnProduct.toFixed(2)} */}
                {(orderedItem?.total || 0)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">
                Discount on product
              </p>
              <p
                className="SingleOngoingOrder__page__DescValue"
                style={{ color: "green" }}
              >
                {`-RM ${(orderedItem?.discountOnProduct || 0).toFixed(2)}`}
              </p>
            </div>
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">Shipping</p>
              <p className="SingleOngoingOrder__page__DescValue">
                RM {(orderedItem?.shippingCost || 0).toFixed(2)}
              </p>
            </div>
            {
              <div className="SingleOngoingOrder_Page_Wrapper">
                <p className="SingleOngoingOrder__page__Desc">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {orderedItem?.couponDiscount && (
                      <div>
                        Coupon discount <span>({orderedItem?.coupon}) </span>
                      </div>
                    )}
                    {orderedItem?.voucherDiscount && (
                      <div style={{ textAlign: "start" }}>
                        Voucher <span>({orderedItem?.vocuher})</span>
                      </div>
                    )}
                  </div>
                  {orderedItem?.redeemedPcoin && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <p>P-Coin Redemption</p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          color: "black",
                        }}
                      >
                        <img src={PCoin} alt="pCoin" />
                        {orderedItem?.redeemedPcoin}
                      </p>
                      <p style={{ color: "black" }}>P-Coins</p>
                    </div>
                  )}
                </p>
                <div
                  className="SingleOngoingOrder__page__DescValue"
                  style={{ color: "green" }}
                >
                  <p>
                    {orderedItem?.couponDiscount &&
                      "-RM" + " " + orderedItem?.couponDiscount.toFixed(2)}
                  </p>
                  <p style={{ textAlign: "end" }}>
                    {orderedItem?.voucherDiscount &&
                      "-RM" + " " + orderedItem?.voucherDiscount.toFixed(2)}
                  </p>
                  <p style={{ textAlign: "end" }}>
                    {orderedItem?.redeemedPcoinValue &&
                      "-RM " +
                      `${parseFloat(orderedItem?.redeemedPcoinValue)?.toFixed(
                        2
                      )}`}
                  </p>
                </div>
              </div>
            }

            {/* 
            <div className="SingleOngoingOrder_Page_Wrapper">
              <p className="SingleOngoingOrder__page__Desc">
                P-Coin redemption
              </p>
              <p className="SingleOngoingOrder__page__DescValue">
                {orderedItem?.redeemedPcoinValue}
              </p>
            </div> */}
            <hr />
            <div className="SingleOngoingOrder_Page_Wrapper">
              <h4 className="SingleOngoingOrder__page__Header">Total</h4>
              <p className="SingleOngoingOrder__page__Value">
                RM{" "}
                {Math.abs(totalProductPrice || 0)?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
          {orderedItem?.trackingNo && (
            <div className="SingleOngoingOrder__page__ShipAddressSec">
              <h3 className="SingleOngoingOrder__page__Header">Tracking</h3>
              <div className="SingleOngoingOrder_Page_Wrapper">
                <p className="SingleOngoingOrder_Page_Wrapper__Head">
                  {orderedItem?.trackingNo}
                </p>
                <p
                  className="SingleOngoingOrder_Page_Wrapper__Link"
                  onClick={(e) => OrderTracker(e, orderedItem?.trackingNo)}
                >
                  <u>Track my order</u>
                </p>
              </div>
            </div>
          )}
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h3 className="SingleOngoingOrder__page__Header">Payment Method</h3>
            {orderedItem?.PaymentMethod &&
              <div className="SingleOngoingOrder_Page_Wrapper">
                <p className="SingleOngoingOrder_Page_Wrapper__Head">
                  {orderedItem?.PaymentMethod?.name}
                </p>
                <img
                  src={`${url}/${orderedItem?.PaymentMethod?.file}`}
                  alt="Payment-Icon"
                  className="Payment__Icon"
                />
              </div>
            }
          </div>
          <div className="SingleOngoingOrder__page__ShipAddressSec">
            <h3 className="SingleOngoingOrder__page__Header">
              Shipping Method
            </h3>
            <div>
              <p className="SingleOngoingOrder_Page_Wrapper__Head">
                {orderedItem?.isSelfPickup &&
                  "Self-Collect from Wisma Pineapple"}
                {!orderedItem?.isSelfPickup &&
                  `Shipping rate RM
                ${(orderedItem?.shippingCost || 0).toFixed(2)} `}
              </p>
            </div>
          </div>
          {orderedItem?.E_invoiceType === "Individual" &&
            <div className="SingleOngoingOrder__page-e-invoice">
              <h3 className="SingleOngoingOrder__page__Header">
                E-Invoice
              </h3>
              <h3 className="SingleOngoingOrder__page__Header text-danger">
                {orderedItem?.E_invoiceType}
              </h3>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Full Name as in IC</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.IcFullName ? orderedItem?.IcFullName : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.IcFullName ? orderedItem?.User?.IcFullName : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-3">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">IC Number</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.IcNumber ? orderedItem?.IcNumber : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.IcNumber ? orderedItem?.User?.IcNumber : "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          }
          {orderedItem?.E_invoiceType === "Company" &&
            <div className="SingleOngoingOrder__page-e-invoice">
              <h3 className="SingleOngoingOrder__page__Header">
                E-Invoice
              </h3>
              <h3 className="SingleOngoingOrder__page__Header text-danger">
                {orderedItem?.E_invoiceType}
              </h3>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Company Name</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.name ? orderedItem?.UserCompnayInfo?.name : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.name : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Company Registration No.</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.regNo ? orderedItem?.UserCompnayInfo?.regNo : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.regNo : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Tax identification No. (TIN)</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.tin ? orderedItem?.UserCompnayInfo?.tin : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.tin : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">MSIC Code</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.msicCode ? orderedItem?.UserCompnayInfo?.msicCode : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.msicCode : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">SST No.</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.sstNo ? orderedItem?.UserCompnayInfo?.sstNo : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.sstNo : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Correspondence Address</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.address ? orderedItem?.UserCompnayInfo?.address : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.address : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Contact No.</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.contactNo ? orderedItem?.UserCompnayInfo?.contactNo : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? `+6${orderedItem?.User?.UserCompnayInfo?.contactNo}` : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-2">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">E-Invoice receiver email address</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.email ? orderedItem?.UserCompnayInfo?.email : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.email : "-"}</span>
                  </div>
                </div>
              </div>
              <div className="frame-divAccount mb-3">
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">Business activity description</div>
                </div>
                <div className="full-name-parentAccount">
                  <div className="full-nameAccount">
                    <span className="flex-shrink-0 me-1">: &nbsp;</span>
                    <span>{orderedItem?.UserCompnayInfo?.businessDescription ? orderedItem?.UserCompnayInfo?.businessDescription : (orderedItem?.status === "Payment Initiated" || orderedItem?.status === "Payment Failed") && orderedItem?.User?.UserCompnayInfo ? orderedItem?.User?.UserCompnayInfo?.businessDescription : "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          }
          {isOpenSuccesModal && (
            <SuccessMessageModal
              isOpenSuccesModal={isOpenSuccesModal}
              setIsOpenSuccessModal={setIsOpenSuccessModal}
            />
          )}
          {openModal && (
            <RateReviewModal
              isOpen={openModal}
              orderedItem={orderedItem}
              selectedProduct={selectedProduct}
              setOpenModal={setOpenModal}
              setIsOpenSuccessModal={setIsOpenSuccessModal}
            />
          )}
        </div>
      )}
    </AccountMenu>
  );
};

export default SingleOngoingOrder;
