import "../../assets/scss/base/pages/cart/OrderingSteps.scss";


const OrderingSteps = () => {
  return (
    <div className="cart-childOrderingSteps">
      <div className="frame-containerOrderingSteps">
        <div className="frame-divOrderingSteps">
          <div className="ellipse-wrapperOrderingSteps">
            <div className="frame-itemOrderingSteps" />
          </div>
          <div className="bagOrderingSteps">Bag</div>
        </div>
        <div className="frame-parent1OrderingSteps">
          <div className="vector-parentOrderingSteps">
            <img className="frame-innerOrderingSteps" alt="" src="/line-31.svg" />
            <div className="frame-itemOrderingSteps" />
          </div>
          <div className="bagOrderingSteps">Address</div>
        </div>
        <div className="frame-parent2OrderingSteps">
          <div className="vector-parentOrderingSteps">
            <img className="frame-innerOrderingSteps" alt="" src="/line-32.svg" />
            <div className="frame-itemOrderingSteps" />
          </div>
          <div className="bagOrderingSteps">Payment</div>
        </div>
      </div>
    </div>
  );
};

export default OrderingSteps;
