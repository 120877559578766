// cartListSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderList: [],
 };

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderList: (state, action) => {
        state.orderList = action.payload
    },
   }
});

export const { 
  setOrderList
 
 } = orderSlice.actions;

export const selectOrderList = (state) => state.order.orderList 


export default orderSlice.reducer;
