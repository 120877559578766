import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Order from "../../components/order/Order";
import { OrderListingService } from "../../service/OrderList.service";
import NoOrder from "../../components/order/NoOrder";
import AccountMenu from "./AccountMenuNavigation/AccountMenu";
//images
import SearchIcon from "../../assets/images/account/Order/SearchIcon.svg";
//Redux
import { setOrderList, selectOrderList } from "../../redux/orderSlice";
//styles
import "../../assets/scss/base/pages/account/OrderPage/OrderPage.scss";
import Loader from "../../components/micro/loader/Loader";
import { current } from "@reduxjs/toolkit";
import { Helmet } from "react-helmet";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderedItems = useSelector(selectOrderList);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [responseLimit, setResponseLimit] = useState(10);
  const [limit, setLimit] = useState(0);

  const fetchCartList = async () => {
    const response = await OrderListingService({ filter: "", responseLimit });
    if (response?.data?.success) {
      setResponseLimit(response?.data?.data.count);

      dispatch(setOrderList(response?.data?.data?.list));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCartList();
  }, [currentPage]);

  const onOfItems = 9;
  const pages = orderedItems?.length / onOfItems;
  const handlePaginationOperation = (Button) => {
    if (Button === "Next") {
      if (currentPage < Math.ceil(pages)) {
        setCurrentPage(currentPage + 1);
        setLimit(limit + onOfItems);
      }
      if (Math.ceil(pages) === limit) {
        setLimit(orderedItems?.length - 1);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLimit(limit - onOfItems);
      }
    }
  };
  return (
    <AccountMenu>
      <Helmet>
                <meta charSet="utf-8" />
                <title > Account | Orders</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <div className="OrderPage">
        <div className="OrderPage__InnerContainer">
          <h3>All Orders</h3>
          {/* <div className="OrderPage__SearchContainer">
            <img
              src={SearchIcon}
              alt="SearchIcon"
              className="OrderPage__SearchIcon"
            />
            <input
              type="text"
              className="OrderPage__SearchInput"
              placeholder="Search order"
            />
          </div> */}
        </div>
        {isLoading ? (
          <Loader style={{ width: "100%" }} />
        ) : orderedItems?.length > 0 ? (
          orderedItems?.slice(limit, onOfItems + limit)?.map((item, index) => {
            return <Order key={index} item={item} />;
          })
        ) : (
          <NoOrder />
        )}
        {pages > 1 && (
          <div className="paginationContainer">
            <p>
              Page {currentPage} of {Math.ceil(pages)}{" "}
            </p>
            <div className="paginateControls">
              <button
                className="paginateBtn"
                onClick={() => handlePaginationOperation("Prev")}
              >
                Prev
              </button>
              <span className="paginateText">{currentPage}</span>
              <button
                className="paginateBtn"
                onClick={() => handlePaginationOperation("Next")}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </AccountMenu>
  );
};

export default Orders;
