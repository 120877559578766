import axios from "axios";
// import { useNavigate } from 'react-router-dom';

import {
  getToken,
  removeTemporarySession,
  removeUserSession,
} from "../utils/AuthService";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;

// const navigate = useNavigate();

/**
 * @function loginUser
 * @param {mobileNo, password}
 * @description This function is used to signin API functioning the user
 */

export const loginUser = async ({
  emailOrMobile,
  password,
  userType,
  recaptchaResponse,
}) => {
  try {
    const result = await axios.post(`${url}/common/loginWithreCAPTCHA`, {
      emailOrMobile,
      password,
      userType,
      recaptchaResponse,
    });
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * @function refreshToken
 * @param {foodauthtoken}
 * @description This function is used to signin API functioning the user
 */
export const refreshToken = async () => {
  try {
    const tokens = getToken();
    const { tokenRefresh, foodRefreshToken } = tokens;
    const result = await axios.post(`${url}/common/u/refresh-tokens`, null,
      {
        headers: {
          Authorization: `Bearer ${tokenRefresh}`,
          foodauthtoken: `${foodRefreshToken}`
        }
      });
    return result;
  } catch (err) {
    return err;
  }
}


/**
 * @function signUpUser
 * @param { name, mobileNo, email, password, password_confirmation }
 * @description This function is used to signup API functioning the user
 */

export const signUpUser = async ({
  username,
  mobileNo,
  email,
  password,
  recaptchaResponse,
}) => {
  try {
    const result = await axios.post(`${url}/user/registerWithreCAPTCHA`, {
      username,
      mobileNo,
      email,
      password,
      recaptchaResponse,
    });
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function logoutService
 * @param navigate
 * @description This function is used to logout API functioning the user
 */

export const logout = async () => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/common/u/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    // removeUserSession();
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function changePassword
 * @param {password, newPassword}
 * @description This function is used to change password for the account page
 */

export const changePassword = async ({ password, newPassword }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/common/u/change-password`,
      {
        password,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function forgetPasswordService
 * @param {mobileNo, userType}
 * @description This function is used to send OTP to user-phone for reset forget-password
 */

export const forgetPassword = async ({ mobileNo, userType }) => {
  try {
    const result = await axios.post(`${url}/common/forgot-password`, {
      mobileNo,
      userType,
    });
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function OTPValidation
 * @param {otpType, otp}
 * @description This function is used to validate OTP
 */

export const OTPValidation = async ({ otpType, otp }) => {
  try {
    const tokens = getToken();
    const { tokenAccess } = tokens;
    const result = await axios.post(
      `${url}/common/user/OTPverify`,
      {
        otpType,
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          "Content-Type": "application/json",
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

export const OTPValidationChangeMobileNumber = async ({ otpType, otp }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;

    const result = await axios.post(
      `${url}/common/user/OTPverifyChangeMobileNo`,
      {
        otpType,
        otp,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function forgetPasswordResendOTP
 * @param phoneNumber
 * @description This function is used to Re-Send OTP to user-phone for reset forget-password
 */

export const resendOTP = async ({ otpType }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/common/user/OTPresend`,
      {
        otpType,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          "Content-Type": "application/json",
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );
    return result;
  } catch (err) {
    return err;
  }
};

/**
 * @function resetPasswordService
 * @param {phoneNumber, code, password, password_confirmation}
 * @description This function is used to reset password
 */

export const resetPassword = async ({ password }) => {
  try {
    const tokens = getToken();
    const { tokenAccess, foodAccessToken } = tokens;
    const result = await axios.post(
      `${url}/common/user/reset-password`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenAccess}`,
          "Content-Type": "application/json",
          foodauthtoken: `${foodAccessToken}`,
        },
      }
    );

    return result;
  } catch (err) {
    return err;
  }
};
