import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// images
import PCoinLarge from "../../assets/P-CoinLargeIcon.png";
import AppStore from "../../assets/app-store.png";
import PlayStore from "../../assets/play-store.png";

const Share = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.pineapple.my';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = 'https://apps.apple.com/lv/app/pineapple-e-store/id1626465032';
    }
  }, []);

  return (
    <div className="container-fluid px-0 min-vh-100">
      <div className="py-5 bg-danger d-flex flex-column justify-content-center align-items-center">
        <img
          className="img-fluid"
          src={PCoinLarge}
          alt="pcoin"
        />
        <span className="mt-4 text-light h2">P-Coin</span>
        <span className="mt-2 text-light h3">Get Our Free App</span>
      </div>
      <div className="mt-5 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <Link to="https://play.google.com/store/apps/details?id=com.pineapple.my" className="me-sm-2">
          <img
            className="img-fluid"
            src={PlayStore}
            alt="pcoin"
            style={{ maxWidth: "165px" }}
          />
        </Link>
        <Link to="https://apps.apple.com/lv/app/pineapple-e-store/id1626465032" className="mt-3 mt-sm-0">
          <img
            className="img-fluid"
            src={AppStore}
            alt="pcoin"
            style={{ maxWidth: "165px" }}
          />
        </Link>
      </div>
    </div>
  );
};

export default Share;