// CategoriesListSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoriesList: [],
  filteredList: [],
  pageCount: 0,
  totalItem: 0
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoriesList: (state, action) => {
        state.categoriesList = action.payload
    },
    setFilteredList: (state, action) => {
        state.filteredList = action.payload
    },
    setPageCount: (state, action) => {
        state.pageCount = action.payload
    },
    setTotalItem: (state, action) => {
        state.totalItem = action.payload
    }
  }
});

export const { 
  setCategoriesList, 
  setFilteredList,
  setPageCount, 
  setTotalItem
 } = categoriesSlice.actions;

export const selectCategoriesList = (state) => state.categories.categoriesList 
export const selectFiltered = (state) => state.categories.filteredList 
export const selectPageCount = (state) => state.categories.pageCount 
export const selectTotalItem  = (state) => state.categories.totalItem 

export default categoriesSlice.reducer;
