/* eslint-disable no-unused-expressions */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// Images
import { passwordChangeSuccess } from "../../assets/images/authentication";
import { logo } from "../../assets/images/authentication";
// Styles
// import "../../assets/scss/base/pages/authentication/ResetPasswordSuccessful.scss";
import "../../assets/scss/base/pages/account/ChangePasswordSuccessMsg/ChangePasswordSucessMsg.scss";
import "../../assets/scss/base/pages/index.scss";
import { setLogout } from "../../redux/authSlice";
import { removeUserSession } from "../../utils/AuthService";
import { Modal } from "reactstrap";
import { setLoginModalToggle } from "../../redux/modalsSlice";

const PasswordChangedSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="PasswordChange__Main__Container">
      <div className="reset__password__successfully__block">
        <img
          // className="logo-2-icon"
          alt="reset password success"
          src={passwordChangeSuccess}
          width="260px"
        />
        <div className="text6">
          <div className="welcome-back-glad">Password Changed!</div>
          <div className="hurray-your-password">
            Hurray! Your password has been changed successfully.
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/");
            dispatch(setLoginModalToggle(true));
            dispatch(setLogout(false));
            removeUserSession();
          }}
          className="password__changed__button"
        >
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default PasswordChangedSuccess;
