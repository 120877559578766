import React from "react";
import banner from "../../assets/errorImage2.svg";

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center", margin: "2rem auto", zIndex: 2001 }}>
      <img src={banner} alt="img" />
      <p
        style={{ margin: "1.5rem auto", fontSize: "30px", fontWeight: "bold" }}
      >
        Page not found
      </p>
    </div>
  );
};

export default NotFoundPage;
