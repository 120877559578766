import React from "react";
import PCoin from "../../assets/images/account/ProfilePCoin/P-Coin.png";
//styles
import "../../assets/scss/base/pages/account/ProfilePCoin/ProfilePCoin.scss";
import moment from "moment";

const ProfilePCoin = ({ transaction }) => {
  return (
    <div className="ProfilePCoin__Container">
      <div className="ProfilePCoin__Inner__Top__Container">
        <div>
          <p className="ProfilePCoin__Container__Main__Head">
            {/* {transaction?.status === "Redeemed" ? "Discount" : "Earned"} on{" "} */}
            {transaction?.referenceType}
          </p>
          <div className="ProfilePCoin__Inner__Inner__Container">
            <img src={PCoin} alt="Coin-Icon" />

            <p
              className={
                transaction?.status === "Redeemed"
                  ? "ProfilePCoin__Text"
                  : "ProfilePCoin__OtherText"
              }
            >
              {transaction?.status === "Redeemed" ? "-" : "+"}
              {transaction?.dealingQuantity} P-Coins
            </p>
          </div>
        </div>
        {transaction?.status === "Redeemed" ? (
          <button className="ProfilePCoin__Btn">Paid</button>
        ) : (
          <button className="ProfilePCoin__OtherBtn">Earned</button>
        )}
      </div>
      <div className="ProfilePCoin__Inner__Bottom__Container">
        <p className="ProfilePCoin__Inner__Bottom__Container__Text">
          {moment(transaction.updatedAt).format("MMM Do, YYYY")}
        </p>
        <p className="ProfilePCoin__Inner__Bottom__Container__Text">
          P-Coin Balance: {transaction?.available}
        </p>
      </div>
    </div>
  );
};

export default ProfilePCoin;
