import React from "react";
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy</title> 
                <meta
      name="description"
      content="We provide and sell Computers & Accessories, Office Solutions, Printing Solutions, Gamers Products, Multimedia Devices, Networking and Storage Products. Available online and at our stores nationwide."
    />
    </Helmet>
      <h2 style={{ margin: "2rem 0" }}>Privacy Policy</h2>
      <p>
        As part of Pineapple.my compliance to The Personal Data Protection Act
        2010 (PDPA) and commitment towards protecting of our member’s personal
        information, Pineapple.my has put in place a personal data protection
        and compliance principles which Pineapple.my stands guided by in the
        provision of our products and services to our members/customers.
      </p>
      <p>
        Please read Pineapple.my Privacy Statement to understand how we use the
        personal data which we may collect from you. By providing your Personal
        Information to us, you are consenting to this Privacy Statement, and the
        collection, the use, the access, the transfer, the storage, and the
        processing of your personal information as describe in this Privacy
        Statement.
      </p>

      <p>1. What does this Privacy Statement About</p>
      <p>This Privacy Statement is about:</p>
      <ul>
        <li>What kind of your personal information we collect</li>
        <li>How your personal information is collected</li>
        <li>How we use your personal information</li>
        <li>When we disclose your personal information</li>
        <li>When we transfer your personal information</li>
        <li>Your right to access and correct your personal data</li>
      </ul>
      <p>&nbsp;</p>
      <p>2. What is Personal Data</p>
      <p>
        Personal data is any information which relates directly or indirectly to
        you. This includes any information which can be used to identify or
        contact you.
      </p>
      <p>
        <br />
        3. What are the kind of Personal Information We Collect
      </p>
      <p>
        In order for us to provide you with our Products and Services, and to
        operate in an efficient and effective manner, we need to collect
        relevant personal information from you. The personal information
        collected by us may include (but is not limited to) the following:-
      </p>
      <ul>
        <li>
          contact information such as your full name, date of birth,
          identification, supporting documents (including MyKad/NRIC/Passport,
          bill statement of utility bills or statements which indicates your
          fullname, address, etc), gender, nationality and race, preferred
          language, current private and/or business address, fixed line
          telephone (home and office), fax number, email address
        </li>
        <li>credit card information and bank account details</li>
        <li>the status of the services you have subscribed from us</li>
        <li>
          your personal interests and preferences to help us to better improve
          our Products and Services
        </li>
        <li>how you use the Products and Services</li>
        <li>
          the equipment you use when accessing our Websites, Products and/or
          Services (such as your handphone, your computer system and platform)
          to customize the service for you
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>4. When and How we collect your personal data?</p>
      <p>We may collect your personal data from your when you:-</p>
      <ul>
        <li>
          you register/subscribe as as new member (Verified Member, Web Store
          Member, etc)
        </li>
        <li>you use our Products and/or Services</li>
        <li>participate in any of our surveys</li>
        <li>participate in any of our marketing campaign and/or promotions</li>
        <li>
          enter and/or participate in any contests, loyalty programmes,
          competition run/organized by us
        </li>
        <li>
          response to any marketing materials we send out (newsletter, etc)
        </li>
        <li>visit or browse our websites</li>
        <li>lodge a complaint with us</li>
        <li>
          provide feedback and/or suggestions to us (via our website or in hard
          copy)
        </li>
      </ul>
      <p>When you access our websites, please be aware that:</p>
      <ul>
        <li>
          your IP address and information about your visit (such as time stamp,
          date, duration etc) will be automatically picked up by Pineapple.my’s
          systems.
        </li>
        <li>
          a “cookie” may be attached when you enter. Cookies are pieces of
          information that websites transfer to your computer’s hard drive for
          record-keeping purposes. The use of cookies is now an industry
          standard, and you will find them used on most major web sites and in
          fact, most browsers are initially set up to accept cookies. If you
          prefer, you can reset your browser either to notify you when you have
          received a cookie, or to refuse to accept cookies. Please also note
          that your user experience will be affected and areas of certain sites
          will not function properly if you set your browser to not accept
          cookies.
        </li>
        <li>
          our websites may contain links to third parties sites and Pineapple.my
          will always try to make it clear when you leave our sites as
          Pineapple.my is not responsible for
        </li>
      </ul>
      <ol>
        <li>such third parties sites and</li>
        <li>
          information which you made available at such third parties sites
        </li>
      </ol>
      <p>
        As such, it would be prudent for you to check on the privacy policy of
        such third parties before disclosing your personal information to such
        third parties and/or sites.
      </p>
      <p>&nbsp;</p>
      <p>5. How We Use Your Personal Information</p>
      <p>
        We may collect and use personal information from you for one or more of
        the following purposes:-
      </p>
      <ul>
        <li>to verify your identity</li>
        <li>
          to assess and process your application(s) /request(s) for our Products
          and/or Services
        </li>
        <li>
          to provide you with the Products and/or Services you have requested
        </li>
        <li>
          to administer and manage the Products and/or Services we provide you
          (including charging, billing, facilitating payments)
        </li>
        <li>
          to investigate and resolve any service issues, billing and fees
          queries, complaints or other inquiries that you submit to us regarding
          our Products and Services
        </li>
        <li>to detect and prevent fraudulent activity</li>
        <li>
          to keep in contact with you and provide you with any information you
          have requested
        </li>
        <li>
          to engage in business transactions in respect of Products and/or
          Services to be offered and provided to you
        </li>
        <li>
          to establish and better manage any business relationship we may have
          with you
        </li>
        <li>
          to process any communications you send us (for example, answering any
          queries and dealing with any complaints and feedback)
        </li>
        <li>
          to help us monitor and improve the performance of our Products and
          Services, our customer support/relations teams and to make these more
          efficient
        </li>
        <li>
          to maintain and develop our business systems and infrastructure,
          including testing and upgrading of these systems
        </li>
        <li>to manage staff training and quality assurance</li>
        <li>
          to notify you about benefits and changes to the features of our
          Products and/or Services
        </li>
        <li>to determine how can we improve services to you</li>
        <li>
          to investigate, respond to, or defend claims made against, or
          involving Pineapple Computer Systems Sdn. Bhd.
        </li>
        <li>to conduct marketing activities (for example, market research)</li>
      </ul>
      <p>&nbsp;</p>
      <p>6. Disclosure of Your Personal Information</p>
      <p>
        As a part of providing you with our Products and/or Services and the
        management and/or operation of the same, we may be required or need to
        disclose information about you to the following third parties:
      </p>
      <ul>
        <li>law enforcement agencies</li>
        <li>government agencies</li>
        <li>
          companies and/or organizations that act as our agents, contractors,
          service providers and/or professional advisers
        </li>
        <li>
          other parties in respect of whom you have given your express or
          implied consent
        </li>
        <li>
          customer inquiry and other customer related services (including
          telemarketing);
        </li>
        <li>data processing services</li>
        <li>information technology services</li>
        <li>media and marketing services (including advertising)</li>
        <li>market research and website usage analysis services</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        subject at all times to any laws (including regulations, guidelines
        and/or obligations) applicable to Pineapple Computer Systems Sdn. Bhd.
      </p>
      <p>
        <br />
        7. If Your Personal Information Provided Is Incomplete
      </p>
      <p>
        Where indicated (for example in registration/application forms), it is
        obligatory you provide your personal information to us to enable us to
        process your application for our Products and/or Services. Should you
        decline to provide such obligatory personal information, we may not be
        able to process your application/request or provide you with our
        products or services.
      </p>
      <p>
        <br />
        8. Access to Your Personal Data
      </p>
      <p>
        We can assist you to access and correct your personal information held
        by us.
      </p>
      <p>
        If you wish to have access to your personal information in our
        possession, or where you are of the opinion that such personal
        information held by us is inaccurate, incomplete, misleading or where
        relevant, not up-to-date, you may make a request to us by email our
        Feedback department. You should quote your UserID, full name, email
        address, mailing/billing address and handphone/ fixed phone number and
        provide brief details of the information you want a copy of in any such
        request. We shall charge a processing fee for searching for and
        providing you with access to your personal information to cover
        administration costs.
      </p>
      <p>
        We will use reasonable efforts to comply with your request to access or
        correct your personal information within 21 days of receiving your
        request and the relevant processing fee.
      </p>
      <p>
        Please note that we may have to withhold access to your personal
        information in certain situations, for example when we are unable to
        confirm your identity or where information requested for is of a
        confidential commercial nature or in the event we receive repeated
        requests for the same information. Nevertheless, we will notify you of
        the reasons for not being able to accede to your request.
      </p>
      <p>
        If you are a European resident, you have the right to access the
        personal information we hold about you and to ask that your personal
        information be corrected, updated, or deleted. If you would like to
        exercise this right, please contact us through the contact information
        below.
      </p>
      <p>
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>
      <p>
        The right to access, update or delete the information We have on You.
        Whenever made possible, you can access, update or request deletion of
        Your Personal Data directly within Your account settings section. If you
        are unable to perform these actions yourself, please contact Us to
        assist You. This also enables You to receive a copy of the Personal Data
        We hold about You.
      </p>
      <p>
        You have the right to ask Us to delete or remove Personal Data when
        there is no good reason for Us to continue processing it.
      </p>
      <p>
        <br />
        9. Your Obligations as A Member
      </p>
      <p>
        You are responsible for providing accurate and complete information to
        us about yourself and any other person whose personal information you
        provide us and for updating this personal information as and when it
        becomes incorrect or out of date by contacting Pineapple.my via the
        contact details provided below. This obligation is a condition to the
        provision of our Products and/or Services to you and/or any other person
        authorized or permitted by you or your organization/company to use the
        Products and/or Services.
      </p>
      <p>
        In the event you may need to provide us with personal information
        relating to third parties (for example about your business partner,
        spouse or children or where you are the designated person in charge
        (from an organization or company) for dealing with us, if you are
        acquiring and are responsible for a service and/or product that they
        will use), you confirm that you have (i) obtained their consent or
        otherwise entitled to provide their personal information to us and for
        us to use accordingly, and (ii) informed them to read this Privacy
        Statement at our website at&nbsp;
        <a
          href="https://www.pineapple.my/privacy-policy/"
          style={{ textDecoration: "none", color: "black" }}
        >
          https://www.pineapple.my/privacy-policy/
        </a>
      </p>
      <p>
        <br />
        10. Updates To Our Privacy Statement
      </p>
      <p>
        We may be required to revise and/or modify this Privacy Statement from
        time to time and the updated version shall apply and supersede any and
        all previous versions, including but not limited to, booklets, leaflets
        or hard copy versions. By continuing to use Pineapple.my’s Products and
        Services after the communication of such revision and/or modification,
        you are accepting and consenting to such revision and/or modification.
        Please check our website and/or our Announcement site for information on
        our most up-to-date practices.
      </p>
      <p>
        <br />
        11. User ID Privacy Policy
      </p>
      <p>
        This website uses Google Analytics, a web analytics service provide by
        Google, that places text files known as “cookies” on your computer to
        help us analyze how users interact with the site. This information is
        then used to further improve our services to all our users.
      </p>
      <p>
        All data uploaded is not personally identifiable and will not be
        traceable to any particular individual or devices.
      </p>
      <p>
        Any website users who do not want their data to be collected with Google
        Analytics can install the Google Analytics opt-out browser add-on. This
        add-on prohibits the Google Analytics script running on the website to
        send any information to Google Analytics. You may visit the Google
        Analytics&nbsp;
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          style={{ textDecoration: "none", color: "black" }}
        >
          opt-out page
        </a>
        &nbsp;and install the add-on for your browser and browse for more
        details on installing or uninstalling the add-on.
      </p>
      <p>
        <br />
        12. Our Contact Details
      </p>
      <p>
        Should you have any queries or concerns in relation to this Privacy
        Statement, kindly contact us at&nbsp;
        <a
          href="mailto:marketing@pineapple.my"
          style={{ textDecoration: "none", color: "black" }}
        >
          marketing@pineapple.my
        </a>
      </p>
    </div>
  );
};

export default withLayout(PrivacyPolicyPage, {
  isProductPage: false,
  isNestedPageName: false,
});
